.box{
    background: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.0705882353), 0 2px 9px 0 rgba(62, 57, 107, 0.0588235294);
    border-radius: 8px;
    padding: 15px;
}
ul{
    padding-left: 0px;
    margin: 0px;
    li{
        list-style: none;
    }
}

// #chart
.legend-default{
    ul{
        padding: 0px;
        margin: 0px;
        display: flex;
        li{
            list-style: none;
            display: flex;
            align-items: center;
            margin-right: 12px;
            &:hover{
                cursor: pointer;
            }
            span{
                font-size: 12px;
                margin: 0px;
                color: rgba(51, 51, 51, 0.6);
                &:first-child{
                    width: 8px;
                    height: 8px;
                    border-radius: 75px;
                    margin-right: 5px;
                    display: inline-block;
                }
            }
        }
    }
    &.dou{
        ul{
            display: block;
            li{
                margin-bottom: 20px;
                span{
                    &:first-child{
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                        border-radius: 2px;
                        margin-right: 5px;
                        display: inline-block;
                    }
                }
            }
        }
        &.d-flex{
            ul{
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                li{
                    margin-bottom: 10px;
                }
            }
        }
    }
    &.column{
        margin-top: 0px;
        li{
            margin-bottom: 0px !important;
            margin-right: 0px;
            span{
                font-size: 11px;
            }
        }
    }
}

// breadcrumb
.bread-crumb{
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fff;
    border-bottom: 3px solid #F6FBFF;
    h3{
        margin-bottom: 5px;
    }
    .fields{
        .p-dropdown,
        input{
            height: 34px;
        }
        &.search{
            svg{
                top: 9px;
            }
        }
    }
}
.p-breadcrumb{
    background: none;
    box-shadow: none;
    border: none;
    margin-bottom: 0px;
    padding: 0px;
    li{
        font-size: 8px;
        font-weight: 500;
        a{
            padding-left: 0px !important;
            padding-right: 0px !important;
            .p-menuitem-text{
                font-size: 13px;
                font-weight: 400;
                color: #A3A9B9 !important;
                font-family: $fontRegular;
                white-space: nowrap;
            }
        }
        &.p-breadcrumb-chevron{
            color: rgba(43, 47, 51, 0.4) !important;
            padding-right: 5px;
            padding-left: 5px;
            margin-bottom: 4px;
        }
        &:last-child{
            a{
                .p-menuitem-text{
                    color: $C-212633 !important;
                }
            }
        }
    }
}


// #button
button{
    &.p-button{
        background: $btnBg;
        border-radius: 4px;
        height: 34px;
        padding: 9px 12px !important;
        border-color: transparent !important;
        min-width: 0px;
        box-shadow: none !important;
        transition: all 0.3s;
        border: 1px solid;
        .p-button-label{
            font-size: 13px;
            font-weight: 500;
        }
        &:enabled{
            &:focus,
            &:hover{
                background: $white;
                color: $btnBg;
                border-color: $btnBg !important;
                border: 1px solid;
                .p-button-icon{
                    color: $btnBg;
                }
                svg{
                    path{
                        fill: $btnBg
                    }
                }
            }
        }
        svg {
            height: 14px;
            width: auto;
        }
        &.box-icon{
            border: 1px solid #E2E6F2 !important;
            border-radius: 4px !important;
            background: none !important;
            width: auto;
            &:hover{
                border: 1px solid #E2E6F2 !important;
            }
        }
        &.p-button-secondary{
            border-color: $C-64748B !important;
            border: 1px solid $C-64748B;
            color: $C-64748B;
            color: $white;
            background: $C-64748B;
            &:focus,
            &:hover{
                background: $C-475569 !important;
                border-color: $C-475569 !important;
                .p-button-label,
                .p-button-icon{
                    color: $white;
                }
            }
        }
        &.box-icon{
            border: 1px solid #E2E6F2;
            border-radius: 4px !important;
            background: none !important;
            width: auto;
            &:hover{
                border: 1px solid #E2E6F2;
            }
        }
        &.p-button-warning{
            border: 1px solid;
            &:focus,
            &:hover{
                background: rgba(251, 192, 45, 0.92) !important;
                border-color: rgba(251, 192, 45, 0.92) !important;
            }
        }
    }

    &.btn-option{
        border: 1px solid $C-E2E6F2 !important;
        background: none;
        width: auto;
        min-width: 0;
        overflow: visible;
        svg{
            path{
                fill: $grey;
            }
        }
        &:hover{
            svg{
                path{
                    fill: $white
                }
            }
        }
        &:focus,
        &:active{
            background: $btnBg !important;
            color: $white !important;
            border-color: transparent !important;
            svg{
                path{
                    fill: $white
                }
            }
        }
    }
    &.btn-trans{
        border: 1px solid $C-E2E6F2;
        background: none;
        color: $grey;
        &:hover{
            svg{
                path{
                    fill: $white
                }
            }
            color: $grey;
        }
    }
    &.btn-add {
      padding: 6px 12px !important;
      background: var(--surface-300) !important;
      color: var(--surface-300-text);
      height: unset;
      font-size: 14px;
      line-height: 1.49857143 !important;
      &:hover {
        background: var(--surface-400) !important;
        color: var(--surface-300-text) !important;
        //border-color: transparent !important;
      }
      &:focus,
      &:active{
        background: var(--surface-700) !important;
        color: var(--surface-300-text) !important;
        //border-color: transparent !important;
      }
    }
  &.p-button-large{
    height: 40px;
  }
    .dot{
        position: absolute;
        right: -3px;
        top: -3px;
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 100%;
        background-color: $C-FF3B49;
        z-index: 1;
    }
    &.btn-cancel{
        background: #fff !important;
    }
}

p-fileupload {
    .p-button{
        height: 34px;
    }
}
.chon-lich-hop{
    .p-disabled{
        background: #efefef !important;
    }
    .p-button{
        height: 40px;
    }
}

// #flex
.flex,
.d-flex,
.grid{
    display: flex;
    &.d-right{
        justify-content: right;
    }
    &.bet{
        justify-content: space-between;
    }
    &.end{
        justify-content: flex-end;
    }
    &.middle{
        align-items: center;
    }
    &.center{
        justify-content: center;
    }
    &.bottom{
        align-items: flex-end;
    }
    &.wrap{
        flex-wrap: wrap;
    }
}

// #gap
.gap16{
    gap: 16px;
}
.gap14{
    gap: 14px;
}
.gap12{
    gap: 12px;
}
.gap10{
    gap: 10px;
}
.gap8{
    gap: 8px;
}
.gap6{
    gap: 6px;
}
.gap4{
    gap: 4px
}
.gap2{
    gap: 2px
}


// #header
.header{
    background: $white;
}


section{
    padding: 12px 12px;
}


ag-grid-angular{
    .ag-root-wrapper{
        border-color: transparent !important;
        border-radius: 3px;
        .ag-header-row {
            border-bottom: 1px solid #e2e2e2;
            box-shadow: 1px 1px 1px #b9b9b9f0;
            .ag-header-cell{
                padding-left: 12px;
                padding-right: 12px;
            }
        }
        .ag-root{
            .ag-header{
                background: none;
                .ag-header-cell-label {
                    .ag-header-cell-text{
                        font-family: "SFProTextRegular";
                        font-size: 0.75rem;
                        color: $C-212633;
                        font-weight: 600;
                    }
                }
                .ag-floating-filter{
                    .ag-input-wrapper{
                        input{
                            border: 1px solid #E2E6F2;
                            border-radius: 4px;
                        }
                    }
                    .ag-floating-filter-button-button{
                        padding: 0px !important;
                    }
                }
                .ag-header-cell{
                    &::after{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        display: block;
                        width: 1px;
                        height: 50%;
                        top: calc(50% - 25%);
                        background: rgba(189, 195, 199, 0.5);
                        right: 0;
                    }
                }
            }
            .ag-row{
                border-color: #f5f5f5;
                background: $white;
                border-width: 1px;
                border-bottom-style: solid;
                &.ag-row-odd{
                    // background: $white;
                }
                &.ag-row-level-1{
                    // padding-top: 10px !important;
                    // padding-bottom: 10px !important;
                    .ag-details-row-fixed-height{
                        background-color: transparent !important;
                        border: 1px solid #ccc;
                        padding: 0px !important;
                        width: calc(100% - 20px);
                        margin: auto;
                    }
                    .ag-cell{
                        border-top: none !important;
                        border-bottom: none !important;
                        background: none !important;
                    }
                    .ag-row-hover{
                        background: #eee !important;
                    }
                    .ag-row-focus {
                        .ag-cell{
                            border-top: 1px solid #9abdf5 !important;
                            border-bottom: 1px solid #9abdf5 !important;
                        }
                    }
                }
                &.ag-row-group-expanded{
                    &.ag-row-focus {
                        .ag-cell{
                            border-top: transparent !important;
                            border-bottom: transparent !important;
                        }
                    }
                    .ag-header{
                        background: #f3f3f3;
                    }
                }
            }
            .ag-cell{
                border: 1px solid transparent;
                padding-left: 11px;
                padding-right: 11px;
                .ag-cell-wrapper{
                    .ag-cell-value{
                        font-family: 'SFProTextRegular';
                        font-weight: 400;
                        font-size: 14px;
                        color: #212633;
                    }
                }
                &.ag-cell-range-selected{
                    background:$white !important;
                }
            }
            .ag-row-focus{
                .ag-cell{
                    border-top: 1px solid #9abdf5 !important;
                    border-bottom: 1px solid #9abdf5 !important;
                    border-left: transparent !important;
                    border-right: transparent !important;
                    background: none !important;
                    &.ag-cell-range-selected{
                        background: none !important;
                    }
                }

            }
            .ag-cell-range-selected{
                background: $white !important;
            }
        }
        .ag-row-hover{
            .ag-cell {
                background: #eee;
            }
        }
    }
}
// Tạm ẩn (manh)
//app-list-grid-tree-angular{
//    .ag-root-wrapper{
//        .ag-root{
//            .ag-row{
//                &.ag-row-level-0 {
//                    background: #aaa !important;
//                    app-button-renderer{
//                        p-splitbutton {
//                            .p-splitbutton{
//                                border: 1px solid #ffffff;
//                                .p-button-label{
//                                    color: #ffffff !important;
//                                }
//                                .p-button-icon{
//                                    &::before{
//                                        color: #fff;
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//                &.ag-row-level-1 {
//                    background: rgba(170, 170, 170, 0.7) !important;
//                    app-button-renderer{
//                        p-splitbutton {
//                            .p-splitbutton{
//                                border: 1px solid #ffffff;
//                                .p-button-label{
//                                    color: #ffffff !important;
//                                }
//                                .p-button-icon{
//                                    &::before{
//                                        color: #fff;
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//                &.ag-row-level-2 {
//                    background: rgba(170, 170, 170, 0.4) !important;
//                    app-button-renderer{
//                        p-splitbutton {
//                            .p-splitbutton{
//                                border: 1px solid #949494;
//                                .p-button-label{
//                                    color: #949494 !important;
//                                }
//                                .p-button-icon{
//                                    &::before{
//                                        color: #949494;
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//                &.ag-row-level-3 {
//                    background: rgba(170, 170, 170, 0.2) !important;
//                    app-button-renderer{
//                        p-splitbutton {
//                            .p-splitbutton{
//                                border: 1px solid #949494;
//                                .p-button-label{
//                                    color: #949494 !important;
//                                }
//                                .p-button-icon{
//                                    &::before{
//                                        color: #949494;
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//                &.ag-row-level-4 {
//                    background: #fff !important;
//                }
//                &.ag-row-hover{
//                    &.ag-row-level-0 {
//                        .ag-cell {
//                            background: #aaa !important;
//                        }
//                    }
//                    &.ag-row-level-1 {
//                        .ag-cell {
//                            background: rgba(170, 170, 170, 0.7) !important;
//                        }
//                    }
//                    &.ag-row-level-2 {
//                        .ag-cell {
//                            background: rgba(170, 170, 170, 0.4) !important;
//                        }
//                    }
//                    &.ag-row-level-3 {
//                        .ag-cell {
//                            background: rgba(170, 170, 170, 0.2) !important;
//                        }
//                    }
//                    &.ag-row-level-4 {
//                        .ag-cell {
//                            background: #fff !important;
//                        }
//                    }
//                }
//            }
//
//        }
//    }
//}

.border-right {
    border-right: 1px solid rgb(221, 226, 235) !important;
}
// paginator
.paginator{
    color: $grey;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    .p-paginator{
        padding: 0px;
        .p-paginator-first, .p-paginator-last {
            display: none !important;
        }
    }

    .p-paginator-rpp-options{
        height: 24px;
        background: $mainBg;
        border-radius: 3px;
        border: none;
        padding: 0px !important;
        .p-dropdown-label{
            padding: 0px 8px;
            line-height: 24px;
            font-size: 14px;
            color: $white;
        }
        .p-dropdown-trigger-icon{
            color: $white;
        }
        .p-dropdown-trigger{
            width:2rem;
        }
    }
    .all_1000000000 {
      .p-paginator-rpp-options{
        width: 80px;
      }
      .p-dropdown-label::before {
        content: "All";
        visibility: visible;
      }
      .p-dropdown-label::after {
        content: "";
      }
      .p-dropdown-label {
        visibility: hidden;
      }

    }
    button{
        border-radius: 2px !important;
        font-size: 14px;
        width: 24px;
        height: 24px !important;
        display: inline-block;
        text-align: center;
        margin: 0px !important;
        min-width: 0px !important;
        font-weight: 500;
        padding: 0px !important;
        line-height: 25px;
        .p-paginator-icon{
            line-height: 24px;
            margin-right: 0px;
        }
        &.p-highlight{
            background: $mainBg !important;
            color: $white !important;
        }
        &.p-paginator-first,
        &.p-paginator-prev,
        &.p-paginator-last,
        &.p-paginator-next{
            .p-paginator-icon{
                color: $C-525B73
            }
        }

    }
}

// #filter
.hrm-filter{
    .p-card{
        box-shadow: none;
    }
}

.wrap-edit-detail{
    // position: relative;
    .border-section{
        .p-panel-content{
            .row{
                margin-left: -8px;
                margin-right: -8px;
                &>div{
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
        }
    }
    .btn-group-edit-detail{
        position: absolute;
        right: 16px;
        top: 110px;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: end;
    }
}
.avatar-radius{
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    min-width: 24px;
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
}

.members-filed{
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    li{
        display: flex;
        align-items: center;
        span{
            &:first-child{
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: 100%;
                display: block;
                background-color: #cccccc8f;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            &:last-child{
                color: #59637E;
                font-size: 16px;
                line-height: 20px;
                font-family: $fontRegular;
                white-space: nowrap;
                display: none;
            }
            &.more-member{
                background: #E2E9FC;
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
                color: #0979FD;
                cursor: pointer;
                display: block;
            }
        }

        &.active{
            span{
                &:last-child{
                    display: block;
                }
            }
        }
        &:first-child{
            span{
                &:last-child{
                    display: block;
                    margin-left: 8px;
                }
            }
        }
    }
}


// #tabview
p-tabview{
    .p-tabview-nav-container{
        .p-tabview-nav-content{
            margin-bottom: 12px;
            .p-tabview-nav{
                border-bottom: 1px solid $C-E2E6F2;
                padding-top: 0px;
                &>li{
                    a{
                        padding: 8px 16px;
                        border-radius: 0;
                        &:hover,
                        &:focus{
                            background: none !important;
                            outline: none;
                            box-shadow: none !important;
                        }
                        // &:hover{
                        //     span{
                        //         color: $activeColor
                        //     }
                        // }
                        .uni-icon{
                            margin-right: 5px;
                        }
                        span{
                            font-weight: 500;
                            color: #212633;
                            white-space: nowrap;
                            &.p-tabview-left-icon{
                                color: $primary
                            }
                        }
                        &:hover{
                            span{
                                &.p-tabview-left-icon{
                                    color: $primary !important
                                }
                            }
                        }
                        @media screen and (max-width: 1440px) {
                            padding: 8px 12px;
                        }
                    }
                    span{
                        &.p-tabview-title{
                          font-weight: 600;
                          line-height: 20px;
                          font-size: 13px;
                          color: #687391;
                        }
                    }
                    &.p-tabview-ink-bar{
                        background: $mainBg;
                        bottom: 11px;
                        display: none;
                    }
                    &.p-highlight{
                        a{
                            span{
                                color: $activeColor;
                                &.p-tabview-left-icon{
                                    color: $primary
                                }
                            }
                            // .pi{
                            //     color: $success;
                            // }
                        }
                    }
                }
            }
        }
    }
    .p-tabview-panels{
        padding: 0px !important;
        background: none !important;
    }
    section{
        padding-left: 0px;
        padding-right: 0px;
    }
    .p-tabview-nav-btn{
        height: 36px;
        box-shadow: 1px 1px 8px #ccc !important;
        border-radius: 0px !important;
        display: block;
        text-align: center;
        border: 1px solid #e9e9e9;
        &:hover{
            background: #f1f1f1 !important;
        }
        .pi{
            color: #212633;
            margin-right: 0px;
        }
    }
    .p-tabview-nav-prev{

    }
    .tab-ver{
        display: flex;
        &>.p-tabview-nav-container{
            width: 15%;
            padding-left: 0;
            .p-tabview-nav-content{
                margin-bottom: 0px;
            }
            .p-tabview-nav{
                display: block;
                padding: 0px;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 8px;
                  }
                  &::-webkit-scrollbar-track {
                    border-radius: 99px;
                    cursor: pointer;
                    box-shadow: inset 0 0 2px #80808078;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    border-radius: 99px;
                        background:#c4c4c5;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #919192;
                }
                li{
                    counter-increment: custom;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    border-bottom: 1px solid #f5f5f5 !important;
                    a{
                        margin-bottom: 0px !important;
                        border-bottom: none !important;
                        padding-left: 5px !important;
                        width: 100%;
                        span{
                            white-space: unset !important;
                            line-height: 1;
                        }
                        .pi{
                            position: absolute;
                            right: 5px;
                        }
                        .uni-icon{
                            &::after{
                                background: none;
                            }
                        }

                    }
                    &:hover{
                        a{
                            background: none !important;
                            color: $activeColor !important;
                            span{
                                color: $activeColor !important;
                            }
                        }
                    }
                    &[role="presentation"]{
                        &:last-child{
                            a{
                                border-bottom: none !important;
                            }
                        }
                    }
                    &:before{
                        content: counter(custom) ". ";
                        font-weight: 500;
                        line-height: 1;
                    }
                    &.p-highlight{
                        &:before{
                            color: $activeColor;
                        }
                    }
                    &:hover{
                        &:before{
                            color: $activeColor;
                        }
                    }
                }
            }
        }
        &>.p-tabview-panels{
            width: 85%;
            padding-left: 16px !important;
            padding-right: 0 !important;
        }

        @media screen and (max-width: 1600px) {
            &>.p-tabview-nav-container{
                width: 18%;
                padding-left: 12px;
            }
            &>.p-tabview-panels{
                width: 82%;
            }
        }
        @media screen and (max-width: 1366px) {
            &>.p-tabview-nav-container{
                width: 22%;
                padding-left: 12px;
            }
            &>.p-tabview-panels{
                width: 78%;
            }
        }
    }
}


// #app-edit-detail
app-edit-detail{
    .border-section{
        background: #fff;
        padding: 13px;
        box-shadow: 0px 0px 3px #e8e8e8;
        border-radius: 2px;
        transition: all 0.3s;
        height: 100%;
        border: 1px solid #ebeaea;
        position: relative;
        &:hover{
            box-shadow: 4px 2px 5px #e8e8e8;
            transition: all 0.3s;
        }
        .p-accordion-header,
        .p-panel-header{
            padding: 0px;
            position: relative;
            .config-detail{
                position: absolute;
                top: 0px;
                right: 0px;
                z-index: 3;
            }
            .title-section{
                color: #0b3a85;
                margin-bottom: 16px;
                position: relative;
                padding-bottom: 5px;
                font-weight: 700;
                font-size: 15px;
                &::after{
                    content: '';
                    width: 32px;
                    height: 4px;
                    background: rgba(11,58,133,.7215686275);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
    .p-accordion-header{
        padding: 0px;
        position: relative;
        .config-detail{
            position: absolute;
            top: 13px;
            right: 13px;
            z-index: 3;
        }
        .title-section{
            color: #0b3a85;
            margin-bottom: 16px;
            position: relative;
            padding-bottom: 5px;
            font-weight: 700;
            font-size: 15px;
            &::after{
                content: '';
                width: 32px;
                height: 4px;
                background: rgba(11,58,133,.7215686275);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}
.p-accordion {
    p-accordiontab {
        .p-accordion-tab{
            box-shadow: 4px 2px 5px #e8e8e8;
            background: #fff !important;
            border-radius: 4px;
            border: 1px solid #ebeaea;
            margin-bottom: 0px !important;
            .p-accordion-content{
                padding: 0 13px 0px 30px;
            }
            height: 100%;
        }
        .p-accordion-header {
            .p-accordion-header-link{
                padding-bottom: 0px !important;
                padding: 13px;
                background: none !important;
                &>div{
                    padding: 0px 0px 0px 20px !important
                }
                .p-accordion-toggle-icon{
                    position: absolute;
                    left: 10px;
                    top: 16px;
                    font-size: 13px;
                    font-weight: bold;
                    color: #0b3a85;
                }
            }
        }
        .border-section{
            box-shadow: none;
            padding: 0px;
            border: none;
        }
    }
}

formly-form{
    &>formly-field{
        &>formly-group{
           &>formly-field{
            &>.grid{
                &>formly-field{
                    padding-bottom: 0px;
                }
            }
           } 
        }
    }
}
formly-wrapper-panel{
    .card{
        box-shadow: 4px 2px 5px #e8e8e8;
        background: #fff !important;
        border-radius: 4px;
        border: 1px solid #ebeaea;
        margin-bottom: 0px !important;
        padding: 13px;
        .card-header{
            color: #0b3a85;
            margin-bottom: 16px;
            position: relative;
            padding-bottom: 5px;
            font-weight: 700;
            font-size: 15px;
            &:after{
                content: "";
                width: 32px;
                height: 4px;
                background: rgba(11, 58, 133, 0.7215686275);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}


// #section
.section-box{
    background: #fff;
    padding: 13px;
    box-shadow: 0px 0px 3px #e8e8e8;
    border-radius: 2px;
    transition: all 0.3s;
    border: 1px solid #ebeaea;
    &:hover{
        box-shadow: 4px 2px 5px #e8e8e8;
        transition: all 0.3s;
    }
}

// #grid buton
app-button-renderer {
    p-splitbutton {
        .p-splitbutton{
            border: 1px solid #b3b3b3;
            border-radius: 4px;
            width: 100%;
            background: none;
            button{
                background: none;
                color: #b3b3b3 !important;
                border: none !important;
                width: auto !important;
                padding: 0 4px !important;
            }
        }
        .p-splitbutton-defaultbutton {
            // border: 1px solid #999797;
            background-color: unset;
            color: #ccc;
            height: 25px !important;
            .p-button-label {
                font-size: 13px
            }
        }

        .p-splitbutton-menubutton {
            height: 25px !important;
            .pi-chevron-down {
                font-size: 11px !important;
                margin-right: 4px !important;
            }
        }
    }
}

p-splitbutton {
    z-index: 9;

    .p-splitbutton {
        .p-splitbutton-defaultbutton{
            max-width: unset;
            min-width: unset;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .p-splitbutton-menubutton {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
    &.no-dropdown{
        .p-splitbutton{
            .p-splitbutton-defaultbutton{
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            .p-button-icon-only{
                display: none;
            }
        }
    }
}

.p-sidebar{
    .p-sidebar-header{
        color: #2B2F33;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        border-bottom: 1px solid #EDF1FA;
        padding: 8px 30px;
        font-family: $fontRegular;
        background: #f8f8f8;
        box-shadow: 1px 1px 2px #cccccc96;
    }
}
p-sidebar{
    .p-sidebar{
        .p-sidebar-content{
            position: static;
            padding-top: 15px !important;
            height: calc(100% - 10px);
        }
        .p-sidebar-header{
            color: #2B2F33;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 1px solid #EDF1FA;
            padding: 8px 30px;
            font-family: $fontRegular;
            background: #f8f8f8;
            box-shadow: 1px 1px 2px #cccccc96;
        }
        app-form-detail {
            &> form.Form {
                .buttons-detail{
                    position: fixed;
                    bottom: 16px;
                    top: auto;
                    right: 40px;
                    justify-content: end;
                }
            }
            form{
                .pi-cog{
                    position: fixed !important;
                    right: 20px !important;
                    bottom: 16px !important;
                    top: auto !important
                }
            }
        }
        .p-sidebar-close-icon{
            margin-right: 0px;
        }
        .p-sidebar-footer{
            min-height: 73px;
            border-top: 1px solid #EDF1FA;
        }
        .p-tabview-panels{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        section{
            padding-left: 0px;
            padding-right: 0px;
        }
        .p-tabview {
            position: static !important;
        }
        .btn-group-edit-detail{
            position: absolute;
            bottom: 16px !important;
            right: 20px !important;
            z-index: 2;
            top: auto !important;
        }
        .p-card{
            box-shadow: none;
            border: none;
            .p-card-body{
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
        .p-sidebar-content{
           .wrap-edit-detail{
            &>.grid{
                margin-top: 0px;
            }
           }
        }
        // .ag-root-wrapper{
        //     border-color: #e8e8e8 !important;
        // }
        // z-index: 2122 !important;
    }
}
// .p-sidebar-mask{
//     z-index: 2121 !important;
// }

p-dialog{
    .p-dialog{
        .btn-group-edit-detail{
            position: absolute;
            top: 27px;
            right: 115px;
            z-index: 2;
        }
        background: #fff;
        .p-dialog-header{
            border-radius: 0px;
            background: #0a58ca;
            box-shadow: inset 0px -1px 0px #F0F0F0;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding: 16px 20px;
            .p-dialog-title{
                color: #fff;

            }
            button{
                &.p-dialog-header-icon,
                &.p-dialog-header-close{
                    color: #fff;
                }

            }
        }
        &.popup-setting{
            .p-dialog-header{
                border-radius: 0px;
                background: $white;
                box-shadow: inset 0px -1px 0px #F0F0F0;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                padding: 16px 20px;
                .p-dialog-title{
                    color: $C-212633;
                }
                button{
                    &.p-dialog-header-icon,
                    &.p-dialog-header-close{
                        color: $C-212633;
                    }

                }
            }
        }
        &.config-form{
            .p-dialog-content{
                padding-left: 15px;
                padding-right: 15px;
            }
            .btn-group-edit-detail{
                top: 19px;
                right: 59px;
                bottom: auto !important;
            }
        }
    }

}
.p-dialog{
    .p-dialog-content{
        padding-top: 20px;
    }
}
.search-em-in{
    &.p-dialog{
        background: #0f204a;
        width: 600px;
        transition: all 0.3s ease;
        &.search-em-result{
            width: 1200px;
            transition: all 0.3s ease;
        }
        .p-image{
            display: block;
            padding-top: 100%;
            position: relative;
            margin-bottom: 10px;
        }
        img{
            object-fit: cover;
            position: absolute;
            top: 0px;
            width: 100%;
            height: 100%;
            left: 0px;
        }
        .p-dialog-content{
            padding: 0px !important;
            background: none;
            .search-in{
                display: flex;
                align-items: center;
                background: #0f204a;
                padding-left: 15px;
                padding-right: 15px;
                .search-group{
                    width: 100%;
                    margin-top: 10px;
                }
                &.res{
                    min-height: 0px;
                }
                .p-autocomplete{
                    input{
                        padding-left: 31px;
                    }
                }
                .col-9{
                    position: relative;
                }
                .pi-search{
                    right: 8px;
                    position: absolute;
                    top: 10px;
                    color: #ccc;
                    z-index: 3;
                    cursor: pointer;
                }
            }
            .search-res{
                padding-left: 10px;
                padding-right: 10px;
                background: #fff;
                margin: auto;
                margin-bottom: 10px;
                border-radius: 4px;
                height: 100%;
            }
        }
        .p-dialog-footer{
            background: #0f204a;
            padding-top: 0px;
            padding-right: 15px;
        }
        .p-dialog-header{
            background: #0f204a;
        color: #ffffff;
        border-bottom: none !important;
        box-shadow: none;
        font-weight: 500;
        }
        .search-group{
            background: #fff;
        }
        .number-res{
            position: absolute;
            right: 40px;
            top: 12px;
            color: #979797;
        }
    }

}
.child-search-emp{
    position: absolute;
    right: 10px;
    top: 35px;
    cursor: pointer;
    z-index: 6;
}



.search-group{
    border: 1px solid #D1D4DC;
    padding: 5px;
    background: #fff;
    border-radius: 4px;
    .p-dropdown-label{
        font-size: 13px !important;
        padding-left: 4px !important;
    }
    input,
    .p-dropdown{
        border: none !important;
    }
    .p-autocomplete{
        width: calc(100% - 50px) !important;
    }
    button{
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 9;
        height: 100%;
        text-align: center;
    }
    .uni-load{
        position: absolute;
        right: 60px;
        top: 10px;
    }
}


.table {
    width: 100%;
    max-width: 100%;
    // Cells
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
            padding: 8px;
            line-height: 1.42857143;
            vertical-align: top;
            border-bottom: 1px solid #ddd;
            text-align: left;
            cursor: pointer;
        }
      }
    }
    // Bottom align for column headings
    > thead > tr > th {
        vertical-align: bottom;
        position: sticky;
        top: 0;
        background: #fff;
        white-space: nowrap;
    }
    tr{
        &.active,
        &:hover{
            background: #f1f1f1;
        }
    }
    // Remove top border from thead by default
    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
      > tr:first-child {
        > th,
        > td {
        }
      }
    }
  }

  .search-content{
    width: calc(100% - 30px);
    margin: auto !important;
    .list-em{
        background: #fff;
        overflow: auto;
        max-height: 400px;
        border-radius: 4px;
    }
    .emInfo{
        position: relative;
        .uni-load{
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
        }
    }
    &>.col-4{
        padding-left: 0px;
    }
    &>.col-8{
        padding-right: 0px;
    }
  }

  .uploaded-title{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

}
[hidden] {
    display: none !important;
}

.p-treenode-icon{
    font-family: 'primeicons';
}
.action-grid-add{
    cursor: pointer;
}

.popup-setting{

    // .ag-body-viewport{
    //     padding-right: 22px;
    // }
    .ag-body-horizontal-scroll{
        position: fixed;
        bottom: 0px;
        left: 0px;
    }
    .p-dialog-content{
        padding-right: 0px;
    }
}

.ag-header-cell{
    .btn-button{
        background: #FFF;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 8px;
        padding: 5px;
        width: 25px;
        height: 25px;
        .action-grid-add{
            color: #0868d9;
            font-weight: 900 !important;
            font-size: 13px;
            cursor: pointer;

        }
        .pi{
            color: #425ea3;
        }
    }
}
.p-menu{

  .p-menuitem-link{
    padding-top: 10px;
    padding-bottom: 10px;
  }
    .p-submenu-header{
        padding: 0px;
    }
}
.p-menu,
.p-tieredmenu{
    padding-top: 0px;
    padding-bottom: 0px;
    .p-menuitem {
        position: relative;
        border-bottom: 1px solid #f4f4f4;
        &:last-child{
            border-bottom: none;
        }
        .p-menuitem-text{
            font-weight: 500;
        }
    }
}
.box-icon{
    border-radius: 4px;
    border: 1px solid;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.border-grey{
        border-color: #E2E6F2 !important;
    }
}

.md-editor-binh-luan{
    .tool-bar{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .btn-group{
            .p-dropdown{
                height: 25px;
                margin-right: 5px;
                padding-right: 15px;
                .p-dropdown-trigger{
                    padding-right: 0px;
                }
                .p-dropdown-trigger-icon{
                    font-size: 13px;
                }
            }
            button{
                margin-right: 5px;
                min-width: 0px;
                padding: 0px 10px;
                vertical-align: middle;
                height: 25px;
                line-height: 24px;
                text-align: center;
                background: #e9e9e9;
                border-radius: 3px;
                cursor: pointer;
                &:hover{
                    background: #d2d2d2;
                }
                i{
                    font-size: 10px;
                    margin-right: 0px;
                }
            }
        }
    }
}

.popup-setting{
    .p-dialog-header{
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f6f6f6;
    }
}

section{
    h3{
        margin-bottom: 10px;
    }

}
.p-dropdown-items{
    .p-dropdown-item{
        padding-top: 10px;
        padding-bottom: 10px;
        &>div{
            height: auto !important;
        }
    }
}

.choose-language{
    .p-overlaypanel-content{
        padding: 0px !important;
        min-width: 180px;
        border-radius: 3px !important;
        z-index: 9999 !important;
        ul li,
        h3{
            padding: 8px 15px;
        }
        ul{
            li{
                font-weight: 500;
                &:hover{
                    cursor: pointer;
                }
                &:hover,
                &.active{
                    background: #f2f3f7;
                }
                .checked{
                    position: absolute;
                    right: 10px;
                }
            }
        }
    }
  }


  .profile{
    .p-avatar{
        width: 32px;
        height: 32px;
        margin-top: 1px;
    }
    .card{
        justify-content: center;
        line-height: 1;
        gap: 8px;
    }
    p-avatar{
        img{
            border-radius: 2px;
        }
    }
    .name{
        color: #212633;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
    }
    .item{
        width: 32px;
        height: 32px;
        display: flex;
        background: rgb(100 100 100 / 10%);
        border-radius: 2px;
        text-align: center;
        align-items: center;
        justify-content: center;
        &:hover{
            cursor: pointer;
            svg{
                path{
                    fill: $mainBg;
                }
            }
        }
    }
  }
  .p-toolbar{
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .justify-content-center{
    &>div{
        justify-content: center;
    }
  }

  p-confirmdialog{
    .p-dialog-header{
        padding: 10px 15px !important;
        background: $mainBg !important;
        .p-dialog-title{
            color: $white;
        }
        .p-dialog-header-icons{
            .pi {
                color: $white;
            }
        }
    }
  }
  app-type-linkurl-drag{
    .linkurl-drag{
      margin-bottom: 16px;
      border: 1px solid #D1D4DC;
      border-radius: 2px;
      padding: 16px;
  }
}
.wrap-upload{
    border: 1px dashed #4C97E4;
    border-radius: 4px;
    margin-bottom: 16px;
    .p-fileupload-buttonbar{
        text-align: center;
        .p-fileupload-choose{
            width: 143px;
            margin-bottom: -40px;
            position: relative;
            z-index: 8;
            background: transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.3333 20.4999V27.8333H3.66667V20.4999H0V31.4999H33V20.4999H29.3333Z' fill='white'/%3E%3Cpath d='M23.0817 12.0849L18.3333 7.35492V22.3333H14.6667V7.35492L9.91833 12.0849L7.33333 9.49992L16.5 0.333252L25.6667 9.49992L23.0817 12.0849Z' fill='white'/%3E%3C/svg%3E%0A");
            background-color: transparent;
            background-repeat: no-repeat;
            background-size: contain;
            width: 33px;
            height: 33px;
            padding: 0px;
            margin: 0px;
            min-width: 0px;
        }
    }
    .p-fileupload-content{
        border: none;
        padding-top: 0px;
        padding-bottom: 20px;
        &.p-fileupload-highlight{
            background: #4C97E4
        }
        p-progressbar,
        .p-fileupload-files{
            display: none;
        }
    }
    .content-upload h3{
        margin-top: 0px;
    }
    .p-button-label{
        display: none;
    }
    .uploaded-title{
        margin-bottom: 5px;
    }
}


.uni-load {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #4C97E4;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    &.loading{
        display: block;
    }
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.file-uploaded{
    li{
        border: 1px solid #4C97E4;
        border-radius: 2px;
        padding: 10px 16px;
        font-size: 12px;
        line-height: 16px;
        color: #465373;
        font-family: $fontRegular;
        margin-bottom: 10px;
        a{
            color: #465373;
            text-decoration: none;
        }
    }
}


.box-view{
    border: 1px solid #ccc;
    border-radius: 4px
}

.infos-import{
    background: #f9f9f9;
    padding: 6px;
    border-radius: 4px;
    li{
        span{
            font-size: 14px;
            margin: 0px 5px 0px 0px;
            &.pi{
                padding-top: 5px;
            }
        }
    }
}

.text-color{
    &.text-danger{
        color: #dc3545 !important;
    }
    &.text-warning{
        color: #ffc107 !important;
    }
    &.text-success{
        color: #198754 !important;
    }
    &.text-dark{
        color: #212529 !important;
    }
    &.text-info{
        color: #0dcaf0 !important;
    }
    &.text-light{
        color: #f8f9fa !important;
    }
}
.text-bold{
    font-weight: bold;
}

app-import-excel
{
    .bread-filter{
        background: #fff;
        width: calc(100% - 24px);
        margin: 12px auto 0px;
    }
}

.right-button{
    .button-filter{
        justify-content: end;
    }
}

.box-weekend{
    max-width: 700px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
    .head{
        background: #0a58ca;
        border-radius: 8px 8px 0px 0px;
        padding: 0px 20px;
        .select-month{
            font-size: 16px;
            color: #ffffff
        }
        &>div{
            width: 120px;
            &.text-right{
                width: 66%;
            }
        }
    }
    .p-dropdown{
        background: #0a58ca;
        border: none;
        &.p-focus{
            box-shadow: none;
        }
        .p-dropdown-trigger{
            .p-dropdown-trigger-icon{
                width: 12px;
                height: 7px;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.410765 0.244078C0.736202 -0.0813592 1.26384 -0.0813592 1.58928 0.244078L6.00002 4.65482L10.4108 0.244078C10.7362 -0.0813592 11.2638 -0.0813592 11.5893 0.244078C11.9147 0.569515 11.9147 1.09715 11.5893 1.42259L6.58928 6.42259C6.26384 6.74803 5.7362 6.74803 5.41076 6.42259L0.410765 1.42259C0.0853278 1.09715 0.0853278 0.569515 0.410765 0.244078Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }
    .p-dropdown-label{
        font-size: 20px;
        color: #fff;
        padding-right: 5px;
        padding-left: 0px;
    }
    .content{
        padding: 0px;
    }
    .days{
        flex-wrap: wrap;
    }
    .a-day{
        color: #BDC4D8;
        width: 14.285%;
        padding: 15px 15px 25px 15px;
        border: 0.5px solid #EDF1FA;
        position: relative;
        &.active,
        &:hover{
            background: #F3F8FF;
            cursor: pointer;
            .date{
                color: #212633;
            }
        }
        label{
            position: absolute;
            top: 0px;
            height: 100%;
            left: 0px;
            width: 100%;
            z-index: 2;
            cursor: pointer;
        }
        .p-checkbox{
            margin-right: 5px;
            .p-checkbox-box{
                border-color: rgba(43, 47, 51, 0.2);
            }
        }
        .date{
            margin-top: 8px;
            display: block;
        }
    }
}

.em-activities{
    .p-card-title {
        text-align: center;
    }
    .wrap-knod{
        .p-knob{
            transition: all 0.3s;
        }
        &:hover{
            cursor: pointer;
            .p-knob{
                svg{
                    box-shadow: 1px 1px 4px #ccc;
                    border-radius: 100%;
                    transition: all 0.3s;
                }
            }
            .p-card-title {
                color: rgb(0 72 172 / 87%) !important;
            }
        }
    }
}
.members{
    .item{
        cursor: pointer;
        &:hover{
            .p-avatar{
                img{
                    box-shadow: 1px 2px 7px #ccc;
                    border-radius: 100%;
                    transition: all 0.3s;
                }
            }
            .content{
                h4{
                    color: rgba(0, 72, 172, 0.87) !important;
                }
            }
        }
    }
}

img{
    max-width: 100%;
}
// .p-sidebar-mask{
//     z-index: 1102 !important;
// }
// p-sidebar{
//     &>.p-sidebar{
//         z-index: 1103 !important;
//         p-sidebar{
//             &>.p-sidebar{
//                 z-index: 1104 !important;
//                 p-sidebar{
//                     &>.p-sidebar{
//                         z-index: 1105 !important;
//                     }
//                 }
//             }
//         }
//     }
// }

.base-info-em{
    background: #F3F4F6;
    ul{
        display: flex;
        border-radius: 4px 4px 0px 0px;
        padding: 11px 17px;
        align-items: center;
    }
    li{
        font-size: 14px;
        color: #2F3E62;
        display: inline-block;
        font-family: 'SFProTextRegular';
        line-height: 1;
        &.spre{
            margin: 0px 16px;
        }
        .value{
            font-family: 'SFProTextMedium';
            font-weight: 500;
            color: #4C97E4
        }
    }
}

.avatar{
    app-type-image{
        .p-image{
            display: block;
            &>img{
                width: 100%;
                height: auto;
            }
        }
    }
}

.border-section{
   .title-section{
        color: #0b3a85;
        margin-bottom: 16px;
        position: relative;
        padding-bottom: 5px;
        font-weight: 700;
        font-size: 15px;
        &::after{
            content: '';
            width: 32px;
            height: 4px;
            background: rgba(11,58,133,.7215686275);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}
.title-section{
    color: #0b3a85;
    margin-bottom: 16px;
    position: relative;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 15px;
    &::after{
        content: '';
        width: 32px;
        height: 4px;
        background: rgba(11,58,133,.7215686275);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.btns-tab{
    position: absolute;
    top: 1px;
    right: 2px;
    left: auto;
    width: auto !important;
    z-index: 2;
}
.btns-sidebar-top{
    position: absolute;
    top: 3px;
    right: 70px;
}

p-tabview{
    .p-tabview{
        position: relative;
    }
    .btn-group-edit-detail{
        top: 1px !important
    }
    .tab-ver{
        .btn-group-edit-detail{
            top: 19px !important
        }
        p-sidebar{
            .btn-group-edit-detail{
                top: auto !important
            }
        }
    }
}

.remove-agroup{
    position: absolute;
    right: 5px;
    top: 46px;
    background: #fff;
    z-index: 2;
    width: 20px;
    height: 20px;
    line-height: 20px !important;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 12px;
    cursor: pointer;
    border-radius: 2px;
}


// #room

.fc-header-toolbar{
    .fc-toolbar-chunk{
        &:first-child{
            button{
                margin-left: 0px;
                background: none !important;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                border: 1px solid rgba(43, 47, 51, 0.1) !important;
                box-shadow: none !important;
                outline: none;
                border-width: 1px;
                height: 48px;
                line-height: 48px;
                padding: 0px 16px;
                border-radius: 0;
                &:nth-child(1){
                    border: none !important;
                    color: #2B2F33 !important;
                    position: relative;
                    padding-right: 40px;
                    &::after{
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        content: '';
                        right: 10px;
                        top: 11px;
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.88914 4.91674C8.32219 4.50431 9.02431 4.50431 9.45736 4.91674L16.1108 11.2533C16.5438 11.6657 16.5438 12.3344 16.1108 12.7469L9.45736 19.0834C9.02431 19.4959 8.32219 19.4959 7.88914 19.0834C7.45609 18.671 7.45609 18.0023 7.88914 17.5899L13.7584 12.0001L7.88914 6.41029C7.45609 5.99786 7.45609 5.32918 7.88914 4.91674Z' fill='white'/%3E%3C/svg%3E%0A");
                    }
                }
                &:nth-child(3){
                    color: #2B2F33 !important;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    opacity: 1 !important;
                    border-radius: 0;
                    border-left: 0px !important;
                    border-right: 0px !important;
                    &:hover{
                        background: #4C97E4 !important;
                        color:  #ffffff !important;
                    }
                    &:disabled{
                        &:hover{
                            background: none !important;
                            color:  #2B2F33 !important;
                        }
                    }
                }
                &:nth-child(2){
                    color:  #4C97E4;
                    border-radius: 2px 0px 0px 2px;
                    &:hover{
                        background: #4C97E4 !important;
                    }
                }
                &:last-child{
                    color:  #4C97E4;
                    border-radius: 0px 2px 2px 0px;
                    &:hover{
                        background: #4C97E4 !important;
                    }
                }
            }
        }
        &:last-child{
            background: #F4F5F7;
            border-radius: 2px;
            padding: 2px;
            .fc-button{
                margin-left: 0px !important;
                background: none !important;
                color: #465373 !important;
                outline: none;
                box-shadow: none !important;
                transition: none !important;
                &.fc-button-active{
                    background: #FFFFFF !important;
                    color: #465373 !important;
                    box-shadow: 0px 2px 10px 2px rgb(112 144 176 / 10%) !important;
                    border-radius: 8px !important;
                    color: #0979FD !important;
                }
            }
            .fc-button-group{
                background: #F4F5F7;
                margin-left: 0px !important;
                border-radius: 2px;
                button{
                    background: none !important;
                    color: #465373;
                    border: none !important;
                    &.fc-button-active{
                        background: #FFFFFF !important;
                        box-shadow: 0px 2px 10px 2px rgb(112 144 176 / 10%) !important;
                        border-radius: 8px !important;
                        color: #0979FD !important;
                    }
                }
                .fc--button{
                    display: none;
                    button{
                        background: none;
                        border: none;
                    }
                }
            }
        }
    }
}
.fc-daygrid-day-events{
    .fc-daygrid-day-top{
        display: none;
    }
    .fc-daygrid-event{
        background: #EBF4EF;
        border-radius: 2px;
        &.fc-event-today{
            background: #FFF6DE;
            margin-bottom: 2px;
        }
        &.fc-event-past{
            background: #FAF0F1;
        }
        .custom-event-label{
            padding: 4px 16px !important;
            .text-bold{
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: #2F3E62;
                margin-bottom: 6px;
            }
            .grid{
                gap: 16px;
                .col-6{
                    padding: 0px;
                    width: auto;
                    &:first-child{
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        color: #59637E;
                    }
                    &:last-child{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        color: #4C97E4;
                        font-family: $fontRegular;
                    }
                }
            }
        }
    }
}
.fc-daygrid-day{
    &.fc-day-past{
        .fc-daygrid-day-frame{
            .fc-daygrid-day-events{
                .fc-daygrid-event{
                    background: #FAF0F1;
                }
            }
        }
    }
}
full-calendar{
    .fc-daygrid-body{
        // .fc-daygrid-day-top{
        //     display: none;
        // }
        table{
            tr{
                td{
                    border: 2px solid #EDF1FA;
                    border-color: #EDF1FA !important;
                }
            }
        }
    }
    .fc-daygrid{
        &>table{
            tr>th{
                border: 2px solid #e0e0e0;
            }
            &>tbody{
                &>tr{
                    // &>td{
                    //     border: none;
                    //     border-left: 1px solid #e0e0e0;
                    // }
                }
            }
        }
    }
}
app-chon-lich-hop{
    .product-detail{
        background: #fff;
    }
}
.warm-cool{
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #4c97e42e;
    gap: 10px;
    .pi{
        color: #4C97E4;
    }
    h4{
        margin-top: 0px;
        margin-bottom: 0px;
        color: #4C97E4;
    }
    p{
        margin-bottom: 0px;
        margin-top: 5px;
    }
}
.fc-timeGridWeek-view{
    table.fc-scrollgrid{
        border-color: #EDF1FA !important;
        border-left: none;
        border-top: none;
    }
    .fc-cell-shaded{
        display: none;
    }
    td {
        &.fc-timegrid-slot-minor{
            border-top-style: none !important;
        }
        &.fc-theme-standard .fc-view-harness td{
            border-color: #EDF1FA !important;
            border-width: 2px !important;
        }
        &.fc-theme-standard .fc-view-harness .fc-cell-shaded{
            border: none !important;
            border-width: 0 !important;
        }
        &.fc-theme-standard .fc-view-harness th{
            &:first-child{
                border: none !important;
            }
        }
        &.fc-theme-standard .fc-view-harness .fc-scrollgrid{
            border: none;
        }
    }
    .fc-col-header {
        thead{
            tr{
                th{
                    border-color: #EDF1FA !important;
                    border-bottom: 2px solid #EDF1FA;
                    div{
                        border-top: 1px solid #EDF1FA;
                    }
                    &:first-child{
                        div{
                            border-top: none;
                        }
                        border-bottom: 0px;
                    }
                }
            }
        }
    }
    .fc-timegrid-cols{
        tbody{
            .fc-timegrid-col{
                border-color: #EDF1FA !important;
                border-left: 2px solid;
                border-right: 2px solid;
                background: none;
            }
        }
    }
    tbody{
        .fc-theme-standard td{
            border-color: #EDF1FA !important;
            border-top: 2px solid;
        }
        .fc-scrollgrid-section-body{
            .fc-timegrid-body{
                tr{
                    td{
                        &.fc-timegrid-slot-lane{
                            border-color: #EDF1FA !important;
                            border-top: 2px solid;
                        }
                    }
                }
            }
        }
        .fc-scrollgrid-section-body{
            td{
                // border-left: none;
                // border-top: none;
                &.fc-timegrid-slot-label-frame{
                    color: #5D6985;
                    font-weight: 400;
                    font-size: 20px;
                }
            }

        }
    }
    .fc .fc-timegrid-slot{
        height: 2.5em;
    }
    .fc-timegrid-body{
        table{
            tr{
                td{
                    &.fc-timegrid-slot-label{
                        color: #5D6985;
                        font-weight: 400;
                        font-size: 16px;
                        vertical-align: top;
                    }
                }
            }
        }
    }
    .fc-timegrid-slots{
        tbody{
            tr{
                td{
                    &:first-child{
                        border: none !important;
                    }
                }
            }
        }
        tr{
           .fc-timegrid-slot{
            min-height: 56px;
            height: 56px;

           }
        }
    }
}
.fc-view{
    .fc-col-header-cell{
        .fc-col-header-cell-cushion {
            display: block;
            padding: 10px;
            span{
                font-size: 16px;
                line-height: 26px;
                color: #525B73;
                font-weight: normal;
            }
            span.week-day{
                display: block;
                color: #2F3E62;
                font-weight: 600;
                font-size: 14px;
                line-height: 26px;
            }
            span.to-day-day{
                background: #4C97E4;
                color: #FFFFFF;
                display: block;
            }
        }
    }
}
.fc-timegrid-col-frame{
    .fc-timegrid-col-events{
        .fc-timegrid-event{
            &.fc-event-past{
                background: #FAF0F1;
                .custom-event-label{
                    background: #FAF0F1;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
                border-color: transparent;
                .fc-event-main{
                    color: #2F3E62;
                }
            }
            &.fc-event-future{
                background: #FFF6DE;
                border-color: transparent;
                .custom-event-label{
                    background: #FFF6DE;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
                color: #2F3E62;
                .fc-event-main{
                    color: #2F3E62;
                }
                &.fc-event-today{
                    background: #EBF4EF;
                    border-color: transparent;
                    .custom-event-label{
                        background: #EBF4EF;
                        padding-top: 0px !important;
                        padding-bottom: 0px !important;
                    }
                    .fc-event-main{
                        color: #2F3E62;
                    }
                }
            }
            .fc-event-main{
                .custom-event-label{
                    .grid{
                        .col-6{
                            &:first-child{
                                padding-left: 0px;
                            }
                            &:last-child{
                                color: #4C97E4;
                            }
                        }
                    }
                }
            }
        }
    }
}


// #list member
.list-member{
    .img{
        width: 32px;
        height: 32px;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
    }
    .room{
        margin-bottom: 16px;
        &>p-checkbox{
            label{
                color: #A3A9B9;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .head{
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
        }
        .title{
            color: #A3A9B9;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            margin: 0px;
        }
        input[type="checkbox"]{
            width: 16px;
            height: 16px;
            background: #F3F4F6;
            border: 1px solid #D1D4DC !important;
            position: relative;
            &::after{
                // content: '';
                // width: 100%;
                // height: 100%;
                // display: block;
                // background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' fill='white'/%3E%3Cpath d='M6.50387 10.0905C6.51822 10.1054 6.53796 10.1138 6.55858 10.1138C6.57921 10.1138 6.59895 10.1054 6.6133 10.0905L11.6414 4.87372C11.8807 4.62543 12.2677 4.62543 12.5071 4.87372C12.7477 5.12334 12.7477 5.52903 12.5071 5.77865L6.99139 11.5013C6.75208 11.7496 6.36509 11.7496 6.12578 11.5013L3.36795 8.63997C3.12735 8.39034 3.12735 7.98466 3.36795 7.73503C3.60726 7.48674 3.99425 7.48674 4.23356 7.73503L6.50387 10.0905Z' fill='white'/%3E%3C/svg%3E%0A");
                // opacity: 0;
                // position: absolute;
                // left: 0px;
                // top: 0;
            }
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                opacity: 1;
                position: absolute;
                left: 0px;
                top: 0;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' fill='%23F3F4F6' stroke='%23D1D4DC'/%3E%3C/svg%3E%0A");
            }
            &:checked{
                background: #4C97E4;
                &::after{
                    opacity: 1;
                }
                &::before{
                    opacity: 0;
                }
            }
        }
    }
    .search{
        margin-bottom: 16px;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 2;
        input{
            border: 1px solid rgba(43, 47, 51, 0.1);
            width: 100%;
            border: 1px solid rgba(43, 47, 51, 0.1);
            height: 40px;
            padding: 10px 16px;
            outline: none !important;
            &:target{
                border: 1px solid rgba(43, 47, 51, 0.1);
                outline: none !important;
            }
        }
        svg{
            position: absolute;
            right: 12px;
            top: 9px;
            z-index: 9999;
        }
    }
    .item{
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #F3F4F6;
        span{
            display: block;
            &:first-child{
                color: #182850;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 4px;
            }
            &:last-child{
                color: #747E96;
                font-size: 14px;
                line-height: 20px;
                font-family: $fontRegular;
            }
        }
    }
}

.list-mch{
    .more{
        color: #4C97E4;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        display: inline-block;
    }
}
.list-mch{
    .wrap-label{
        margin-bottom: 18px;
    }
    ul{
        li{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #465373;
            padding: 10px 16px;
            border: 1px solid #4C97E4;
            border-radius: 2px;
            margin-bottom: 8px;
            &.more{
                display: inline-block;
                border: none;
                color: #4C97E4;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
            }
        }
    }
    padding: 16px;
    border: 1px solid #E8E9ED;
    border-radius: 2px;
}

.btn-active-on-off{
    background: #BDC2CF;
    width: auto !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    cursor: auto !important;
    &:hover{
        background: #BDC2CF !important;
    }
    &.active{
        background: #4C97E4;
        cursor: pointer !important;
    }
}
p-button{
    &.active{
        .btn-active-on-off{
            background: #4C97E4;
            cursor: pointer !important;
        }
    }
}
.add-time-notis{
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    input{
        border: 1px solid #D1D4DC;
        padding-right: 35px;
        &:focus{
            border: 1px solid #0979FD !important;
            box-shadow: none !important;
        }
    }
    .buttons{
        position: absolute;
        z-index: 2;
        top: 0px;
        right: auto;
        left: 100%;
        display: flex;
    }
    p-button{

        button{
            min-width: 0px;
        }
        .p-button-label{
            display: none;
        }
    }
}


.product-detail{
    .buttons-detail{
        position: absolute;
        top: 15px;
        right: 39px;
    }
    .setting-form{
        top: 20px !important;
    }
}