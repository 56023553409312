/* #p-menubar */
.p-menubar {
    background: #1d2452;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;
    border-radius: 0px;
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 99;
    .p-menubar-root-list{
        & > .p-menuitem {
            &> .p-menuitem-link{
                background: none !important;
                padding: 15px 12px 15px !important;
                &:focus{
                    box-shadow: none !important;
                }
                &:not(.p-disabled):hover {
                    .p-menuitem-text{
                        color: $white;
                    }
                    background: none !important;
                    box-shadow: none;
                    .p-submenu-icon,
                    .p-menuitem-icon{
                        color: $activeColor !important;
                    }
                }
                .p-menuitem-icon{
                    color: $white;
                    margin-right: 0.5rem;
                }
            }
            &.parent_active{
                &>.p-menuitem-link{
                    .p-submenu-icon{
                        color: $activeColor;
                    }
                    .p-menuitem-text{
                        color: $activeColor !important;
                    }
                }
            }
            &>.p-menuitem-link{
                .p-menuitem-text{
                    font-family: "SFProTextRegular";
                    white-space: nowrap !important ;
                    color: $white !important;
                    font-size: 0.830rem;
                }
                .p-submenu-icon{
                    color: $white;
                    margin-left: 6px;
                }
            }
            &.parent_active{
                &>a{
                    &>.p-menuitem-text{
                        color: $mainBg !important;
                    }
                    .p-submenu-icon{
                        color: $activeColor;
                    }
                }
            }
            &>a{
                &:hover{
                    &>.p-menuitem-text{
                        color: $activeColor !important;
                    }
                }
            }
            &.parent_active{
                &>a{
                    &>.p-menuitem-text{
                        color: $C-f68c1f;
                    }
                }
                .p-menuitem-icon{
                    color: $C-f68c1f;
                }
                .p-submenu-icon{
                    color: $C-f68c1f;
                }
                .p-submenu-list{
                    &>li.active{
                        &>a{
                            background: $C-f68c1f;
                            .p-menuitem-text{
                                color: $white;
                            }
                        }
                    }
                }
            }
            .p-submenu-list{
                &>li{
                    &>a{
                        padding: 12px 16px !important;
                        font-weight: 400;
                        border-bottom: 1px solid #f4f4f4;
                        .p-menuitem-icon{
                            margin-right: 4px;
                            display: none;
                        }
                        .p-menuitem-text{
                            color: #000000;
                            white-space: nowrap;
                        }
                    }
                }
            }
            &.p-menuitem-active {
                &> .p-menuitem-link {
                    .p-submenu-icon{
                        color: $white !important;
                    }
                }
            }
        }
        .p-submenu-list{
            background: $white;
            margin-top: 10px !important;
            overflow: visible !important;
            z-index: 9999 !important;
            width: auto;
            max-height: 535px;
            overflow: auto !important;
            padding-top: 0px;
            padding-bottom: 0px;
            box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
            &::-webkit-scrollbar {
                width: 8px;
              }
            &::-webkit-scrollbar-track {
                border-radius: 99px;
                cursor: pointer;
                box-shadow: inset 0 0 2px #80808078;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                border-radius: 99px;
                    background:#c4c4c5;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #919192;
            }
            li{
                a{
                    &:focus,
                    &:hover{
                        background: $activeColor;
                        span{
                            color: $white !important;
                        }
                    }
                }
                &>.parent_active{
                    a{
                        background: $activeColor;
                        span{
                            color: $white !important;
                        }
                    }
                }
            }
            &>li{
                &>a{
                    color: $C-212633;
                }
            }
        }
        &>.parent_active{
            &>.p-menuitem-link{
                span{
                    color: $activeColor !important;
                }
            }
        }
    }
    .p-menuitem-link{
        .p-menuitem-text{
            color: $C-212633;
            font-weight: 500;
        }
    }
}
