$colorIcon: rgb(82, 91, 115);
$IconHover: #0B3A85;
$orangerColor: rgb(255, 165, 0);
$redColor: rgb(255, 59, 72);
$greenColor:rgb(52, 199, 89);
$whiteColor: rgb(255, 255, 255);
$blackColor: rgba(0, 0, 0, 0.9);
$mainColor: rgba(0, 0, 0, 0.9);
.uni-icon{
    position: relative;
    line-height: 1;
    display: inline-block;
    &:hover{
        cursor: pointer;
    }
    &::after{
        width: 18px;
        height: 18px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        display: block;
    }
    &.uni-overview{
        &::after { background-image: overview($colorIcon); }
        &:hover{ &::after{ background-image: overview($IconHover); } }
    }
    &.uni-users{
        &::after { background-image: users($colorIcon); }
        &:hover{ &::after{ background-image: users($IconHover); } }
    }
    &.uni-calendar{
        &::after { background-image: calendar($colorIcon); }
        &:hover{ &::after{ background-image: calendar($IconHover); } }
    }
    &.uni-note-money{
        &::after { background-image: noteMoney($colorIcon); }
        &:hover{ &::after{ background-image: noteMoney($IconHover); } }
    }
    &.uni-setting{
        &::after { background-image: setting($colorIcon); }
        &:hover{ &::after{ background-image: setting($IconHover); } }
    }
    &.uni-user-rate{
        &::after { background-image: userRate($colorIcon); }
        &:hover{ &::after{ background-image: userRate($IconHover); } }
    }
    &.uni-home-building{
        &::after { background-image: homeBuilding($colorIcon); }
        &:hover{ &::after{ background-image: homeBuilding($IconHover); } }
    }
    &.uni-note{
        &::after { background-image: note($colorIcon); }
        &:hover{ &::after{ background-image: note($IconHover); } }
    }
    &.uni-note-user{
        &::after { background-image: noteUser($colorIcon); }
        &:hover{ &::after{ background-image: noteUser($IconHover); } }
    }
    &.uni-learning-tools{
        &::after { background-image: learningTools($colorIcon); }
        &:hover{ &::after{ background-image: learningTools($IconHover); } }
    }
    &.uni-money{
        &::after { background-image: money($colorIcon); }
        &:hover{ &::after{ background-image: money($IconHover); } }
    }
    &.uni-user-group{
        &::after { background-image: getUserGroup($colorIcon); }
        &:hover{ &::after{ background-image: getUserGroup($IconHover); } }
    }
    &.uni-user-plus{
        &::after { background-image: getUserPlus($colorIcon); }
        &:hover{ &::after{ background-image: getUserPlus($IconHover); } }
    }
    &.uni-user-ban{
        &::after { background-image: getUserBan($colorIcon); }
        &:hover{ &::after{ background-image: getUserBan($IconHover); } }
    }
    &.uni-setting{
        &::after { background-image: getSetting($colorIcon); }
        &:hover{ &::after{ background-image: getSetting($IconHover); } }
    }
    &.uni-full-screen{
        &::after { background-image: getFullScreen($colorIcon); }
        &:hover{ &::after{ background-image: getFullScreen($IconHover); } }
    }
    &.uni-home{
        &::after { background-image: home($colorIcon); }
        &:hover{ &::after{ background-image: home($IconHover); } }
    }
    &.uni-user{
        &::after { background-image: user($colorIcon); }
        &:hover{ &::after{ background-image: user($IconHover); } }
    }
    &.uni-noti{
        &::after { background-image: noti($colorIcon); }
        &:hover{ &::after{ background-image: noti($IconHover); } }
    }
    &.uni-note-checked{
        &::after { background-image: noteChecked($colorIcon); }
        &:hover{ &::after{ background-image: noteChecked($IconHover); } }
    }
    &.uni-money-cicle{
        &::after { background-image: moneyCicle($colorIcon); }
        &:hover{ &::after{ background-image: moneyCicle($IconHover); } }
    }
    &.uni-protect{
        &::after { background-image: protect($colorIcon); }
        &:hover{ &::after{ background-image: protect($IconHover); } }
    }
    &.uni-setting-transparent{
        &::after { background-image: settingTransparent($colorIcon); }
        &:hover{ &::after{ background-image: settingTransparent($IconHover); } }
    }
    &.uni-narrow-down{
        &::after { background-image: narrowDown($colorIcon); }
        &:hover{ &::after{ background-image: narrowDown($IconHover); } }
    }
    &.uni-delete{
        &::after { background-image: delete($colorIcon); }
        &:hover{ &::after{ background-image: delete($IconHover); } }
    }
    &.uni-edit{
        &::after { background-image: edit($colorIcon); }
        &:hover{ &::after{ background-image: edit($IconHover); } }
    }
    &.uni-plus-cicle{
        &::after { background-image: plusCicle($colorIcon); }
        &:hover{ &::after{ background-image: plusCicle($IconHover); } }
    }
    &.uni-plus{
        &::after { background-image: plus($colorIcon); }
        &:hover{ &::after{ background-image: plus($IconHover); } }
    }
    &.uni-upload{
        &::after { background-image: upload($colorIcon); }
        &:hover{ &::after{ background-image: upload($IconHover); } }
    }
    &.uni-calendar{
        &::after { background-image: calendar($colorIcon); }
        &:hover{ &::after{ background-image: calendar($IconHover); } }
    }
    &.uni-config{
        &::after { background-image: config($colorIcon); }
        &:hover{ &::after{ background-image: config($IconHover); } }
    }
    &.uni-cancel-cicle{
        &::after { background-image: cancelCicle($colorIcon); }
        &:hover{ &::after{ background-image: cancelCicle($IconHover); } }
    }
    &.uni-checked-cicle{
        &::after { background-image: checkedCicle($colorIcon); }
        &:hover{ &::after{ background-image: checkedCicle($IconHover); } }
    }
    &.uni-close{
        &::after { background-image: close($colorIcon); }
        &:hover{ &::after{ background-image: close($IconHover); } }
    }
    &.uni-narrow-long-left{
        &::after { background-image: narrowLongLeft($colorIcon); }
        &:hover{ &::after{ background-image: narrowLongLeft($IconHover); } }
    }
    &.uni-file-attach{
        &::after { background-image: fileAttach($colorIcon); }
        &:hover{ &::after{ background-image: fileAttach($IconHover); } }
    }
    &.uni-envelope{
        &::after { background-image: envelope($colorIcon); }
        &:hover{ &::after{ background-image: envelope($IconHover); } }
    }
    &.uni-reload{
        &::after { background-image: reload($colorIcon); }
        &:hover{ &::after{ background-image: reload($IconHover); } }
    }
    &.uni-config-table{
        &::after { background-image: configTable($colorIcon); }
        &:hover{ &::after{ background-image: configTable($IconHover); } }
    }
    &.uni-warning-cicle{
        &::after { background-image: warningCicle($colorIcon); }
        &:hover{ &::after{ background-image: warningCicle($IconHover); } }
    }
    &.uni-options{
        &::after { background-image: options($colorIcon); }
        &:hover{ &::after{ background-image: options($IconHover); } }
    }
    &.uni-search{
        &::after { background-image: search($colorIcon); }
        &:hover{ &::after{ background-image: search($IconHover); } }
    }
    &.uni-save{
        &::after { background-image: save($colorIcon); }
        &:hover{ &::after{ background-image: save($IconHover); } }
    }
    &.uni-create-contract{
        &::after { background-image: createContract($colorIcon); }
        &:hover{ &::after{ background-image: createContract($IconHover); } }
    }
    &.uni-work-off{
        &::after { background-image: workOff($colorIcon); }
        &:hover{ &::after{ background-image: workOff($IconHover); } }
    }
    &.uni-shuffle{
        &::after { background-image: shuffle($colorIcon); }
        &:hover{ &::after{ background-image: shuffle($IconHover); } }
    }
    &.uni-exit-cicle-fill{
        &::after { background-image: exitCicleFill($colorIcon); }
        &:hover{ &::after{ background-image: exitCicleFill($IconHover); } }
    }
    &.uni-import{
        &::after { background-image: import($colorIcon); }
        &:hover{ &::after{ background-image: import($IconHover); } }
    }
    &.uni-caculator{
        &::after { background-image: caculator($colorIcon); }
        &:hover{ &::after{ background-image: caculator($IconHover); } }
    }
    &.uni-pen-line{
        &::after { background-image: penLine($colorIcon); }
        &:hover{ &::after{ background-image: penLine($IconHover); } }
    }
    &.uni-check-square-line{
        &::after { background-image: checkSquareLine($colorIcon); }
        &:hover{ &::after{ background-image: checkSquareLine($IconHover); } }
    }
    &.uni-document-note{
        &::after { background-image: documentNote($colorIcon); }
        &:hover{ &::after{ background-image: documentNote($IconHover); } }
    }
    &.uni-structure-fill{
        &::after { background-image: structureFill($colorIcon); }
        &:hover{ &::after{ background-image: structureFill($IconHover); } }
    }
    &.uni-refresh{
        &::after { background-image: refresh($colorIcon); }
        &:hover{ &::after{ background-image: refresh($IconHover); } }
    }
    &.uni-arrow-down-fill{
        &::after { background-image: arrowDownFill($colorIcon); }
        &:hover{ &::after{ background-image: arrowDownFill($IconHover); } }
    }
    &.uni-update{
        &::after { background-image: update($colorIcon); }
        &:hover{ &::after{ background-image: update($IconHover); } }
    }
    &.uni-note-basic{
        &::after { background-image: noteBasic($colorIcon); }
        &:hover{ &::after{ background-image: noteBasic($IconHover); } }
    }
    &.uni-lock-open-fill{
        &::after { background-image: lockOpenFill($colorIcon); }
        &:hover{ &::after{ background-image: lockOpenFill($IconHover); } }
    }
    &.uni-lock-fill{
        &::after { background-image: lockFill($colorIcon); }
        &:hover{ &::after{ background-image: lockFill($IconHover); } }
    }
    &.uni-copy-line{
        &::after { background-image: copyLine($colorIcon); }
        &:hover{ &::after{ background-image: copyLine($IconHover); } }
    }
    &.uni-gmail-line{
        &::after { background-image: gmailLine($colorIcon); }
        &:hover{ &::after{ background-image: gmailLine($IconHover); } }
    }
    &.uni-icon-square{
        &::after { background-image: iconSquare($colorIcon); }
        &:hover{ &::after{ background-image: iconSquare($IconHover); } }
    }
    &.uni-chevron-right{
        &::after { background-image: chevronRight($colorIcon); }
        &:hover{ &::after{ background-image: chevronRight($IconHover); } }
    }
    &.uni-folder{
        &::after { background-image: folder($colorIcon); }
        &:hover{ &::after{ background-image: folder($IconHover); } }
    }
    &.uni-phone{
        &::after { background-image: phone($colorIcon); }
        &:hover{ &::after{ background-image: phone($IconHover); } }
    }
    &.uni-door{
        &::after { background-image: door($colorIcon); }
        &:hover{ &::after{ background-image: door($IconHover); } }
    }
    &.uni-notify-fill{
        &::after { background-image: notifyFill($colorIcon); }
        &:hover{ &::after{ background-image: notifyFill($IconHover); } }
    }
    &.uni-clock{
        &::after { background-image: clock($colorIcon); }
        &:hover{ &::after{ background-image: clock($IconHover); } }
    }
    &.uni-camera{
        &::after { background-image: camera($colorIcon); }
        &:hover{ &::after{ background-image: camera($IconHover); } }
    }
    &.uni-send{
        &::after { background-image: send($colorIcon); }
        &:hover{ &::after{ background-image: send($IconHover); } }
    }
    &.uni-document-money{
        &::after { background-image: documentMoney($colorIcon); }
        &:hover{ &::after{ background-image: documentMoney($IconHover); } }
    }
    &.uni-caculator-fill{
        &::after { background-image: caculatorFill($colorIcon); }
        &:hover{ &::after{ background-image: caculatorFill($IconHover); } }
    }
    &.uni-vector{
        &::after { background-image: vector($colorIcon); }
        &:hover{ &::after{ background-image: vector($IconHover); } }
    }

    &.uni-more{
        &::after { background-image: more($colorIcon); }
        &:hover{ &::after{ background-image: more($IconHover); } }
    }
    &.uni-table{
        &::after { background-image: tableGrid($colorIcon); }
        &:hover{ &::after{ background-image: tableGrid($IconHover); } }
    }
    &.uni-language{
        &::after { background-image: language($colorIcon); }
        &:hover{ &::after{ background-image: language($IconHover); } }
    }
    &.uni-work{
        &::after { background-image: work($colorIcon); }
        &:hover{ &::after{ background-image: work($IconHover); } }
    }
    &.uni-dots{
        &::after { background-image: dots($colorIcon); }
        &:hover{ &::after{ background-image: dots($IconHover); } }
    }
    &.uni-export{
        &::after { background-image: export($colorIcon); }
        &:hover{ &::after{ background-image: export($IconHover); } }
    }
    &.uni-contact-fill{
        &::after { background-image: contactFill($colorIcon); }
        &:hover{ &::after{ background-image: contactFill($IconHover); } }
    }
    &.uni-connect{
        &::after { background-image: connect($colorIcon); }
        &:hover{ &::after{ background-image: connect($IconHover); } }
    }
    &.uni-check-fill{
        &::after { background-image: checkFill($colorIcon); }
        &:hover{ &::after{ background-image: checkFill($IconHover); } }
    }
    &.uni-money-fill{
        &::after { background-image: moneyFill($colorIcon); }
        &:hover{ &::after{ background-image: moneyFill($IconHover); } }
    }
    &.uni-user-contact-fill{
        &::after { background-image: userContactFill($colorIcon); }
        &:hover{ &::after{ background-image: userContactFill($IconHover); } }
    }
    &.uni-user-setting-fill{
        &::after { background-image: userSettingFill($colorIcon); }
        &:hover{ &::after{ background-image: userSettingFill($IconHover); } }
    }
    &.uni-note-fill{
        &::after { background-image: noteFill($colorIcon); }
        &:hover{ &::after{ background-image: noteFill($IconHover); } }
    }
    &.uni-language-fill{
        &::after { background-image: languageFill($colorIcon); }
        &:hover{ &::after{ background-image: languageFill($IconHover); } }
    }





    &.uni-flag-vn{
        &::after { background-image: flagVN($colorIcon); }
    }
    &.uni-flag-en{
        &::after { background-image: flagEN($colorIcon); }
    }

}
.p-highlight, a:hover{
    .uni-icon{
        &.uni-overview{ &::after { background-image: overview($IconHover); }}
        &.uni-users{ &::after { background-image: users($IconHover); } }
        &.uni-calendar{ &::after { background-image: calendar($IconHover); } }
        &.uni-note-money{ &::after { background-image: noteMoney($IconHover); } }
        &.uni-setting{ &::after { background-image: setting($IconHover); } }
        &.uni-user-rate{ &::after { background-image: userRate($IconHover); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($IconHover); } }
        &.uni-note{ &::after { background-image: note($IconHover); } }
        &.uni-note-user{ &::after { background-image: noteUser($IconHover); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($IconHover); } }
        &.uni-money{  &::after { background-image: money($IconHover); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($IconHover); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($IconHover); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($IconHover); } }
        &.uni-setting{ &::after { background-image: getSetting($IconHover); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($IconHover); } }
        &.uni-home{ &::after { background-image: home($IconHover); } }
        &.uni-user{ &::after { background-image: user($IconHover); } }
        &.uni-noti{ &::after { background-image: noti($IconHover); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($IconHover); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($IconHover); } }
        &.uni-protect{ &::after { background-image: protect($IconHover); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($IconHover); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($IconHover); } }
        &.uni-delete{ &::after { background-image: delete($IconHover); } }
        &.uni-edit{  &::after { background-image: edit($IconHover); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($IconHover); } }
        &.uni-plus{ &::after { background-image: plus($IconHover); } }
        &.uni-upload{ &::after { background-image: upload($IconHover); } }
        &.uni-calendar{ &::after { background-image: calendar($IconHover); } }
        &.uni-config{ &::after { background-image: config($IconHover); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($IconHover); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($IconHover); } }
        &.uni-close{ &::after { background-image: close($IconHover); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($IconHover); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($IconHover); } }
        &.uni-envelope{  &::after { background-image: envelope($IconHover); } }
        &.uni-reload{ &::after { background-image: reload($IconHover); } }
        &.uni-config-table{  &::after { background-image: configTable($IconHover); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($IconHover); } }
        &.uni-options{ &::after { background-image: options($IconHover); }}
        &.uni-search{ &::after { background-image: search($IconHover); } }
        &.uni-save{ &::after { background-image: save($IconHover); } }
        &.uni-create-contract{ &::after { background-image: createContract($IconHover); } }
        &.uni-work-off{  &::after { background-image: workOff($IconHover); } }
        &.uni-shuffle{ &::after { background-image: shuffle($IconHover); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($IconHover); } }
        &.uni-import{  &::after { background-image: import($IconHover); } }
        &.uni-caculator{ &::after { background-image: caculator($IconHover); } }
        &.uni-pen-line{ &::after { background-image: penLine($IconHover); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($IconHover); } }
        &.uni-document-note{ &::after { background-image: documentNote($IconHover); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($IconHover); } }
        &.uni-refresh{ &::after { background-image: refresh($IconHover); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($IconHover); } }
        &.uni-update{ &::after { background-image: update($IconHover); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($IconHover); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($IconHover); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($IconHover); } }
        &.uni-copy-line{ &::after { background-image: copyLine($IconHover); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($IconHover); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($IconHover); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($IconHover); } }
        &.uni-folder{ &::after { background-image: folder($IconHover); } }
        &.uni-phone{ &::after { background-image: phone($IconHover); } }
        &.uni-door{ &::after { background-image: door($IconHover); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($IconHover); } }
        &.uni-clock{ &::after { background-image: clock($IconHover); } }
        &.uni-camera{ &::after { background-image: camera($IconHover); } }
        &.uni-send{ &::after { background-image: send($IconHover); } }
        &.uni-document-money{ &::after { background-image: documentMoney($IconHover); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($IconHover); } }
        &.uni-vector{ &::after { background-image: vector($IconHover); } }
        &.uni-more{ &::after { background-image: more($IconHover); } }
        &.uni-table{&::after { background-image: tableGrid($IconHover); }}
        &.uni-language{  &::after { background-image: language($IconHover); }}
        &.uni-work{  &::after { background-image: work($IconHover); }}
        &.uni-dots{  &::after { background-image: dots($IconHover); }}
        &.uni-export{  &::after { background-image: export($IconHover); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($IconHover); }}
        &.uni-connect{  &::after { background-image: connect($IconHover); }}
        &.uni-check-fill{  &::after { background-image: checkFill($IconHover); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($IconHover); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($IconHover); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($IconHover); }}
        &.uni-note-fill{  &::after { background-image: noteFill($IconHover); }}
        &.uni-language-fill{  &::after { background-image: languageFill($IconHover); }}
    }
}
.uni-icon{
    &.uni-orange-color{
        &.uni-overview{ &::after { background-image: overview($orangerColor); }}
        &.uni-users{ &::after { background-image: users($orangerColor); } }
        &.uni-calendar{ &::after { background-image: calendar($orangerColor); } }
        &.uni-note-money{ &::after { background-image: noteMoney($orangerColor); } }
        &.uni-setting{ &::after { background-image: setting($orangerColor); } }
        &.uni-user-rate{ &::after { background-image: userRate($orangerColor); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($orangerColor); } }
        &.uni-note{ &::after { background-image: note($orangerColor); } }
        &.uni-note-user{ &::after { background-image: noteUser($orangerColor); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($orangerColor); } }
        &.uni-money{  &::after { background-image: money($orangerColor); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($orangerColor); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($orangerColor); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($orangerColor); } }
        &.uni-setting{ &::after { background-image: getSetting($orangerColor); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($orangerColor); } }
        &.uni-home{ &::after { background-image: home($orangerColor); } }
        &.uni-user{ &::after { background-image: user($orangerColor); } }
        &.uni-noti{ &::after { background-image: noti($orangerColor); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($orangerColor); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($orangerColor); } }
        &.uni-protect{ &::after { background-image: protect($orangerColor); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($orangerColor); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($orangerColor); } }
        &.uni-delete{ &::after { background-image: delete($orangerColor); } }
        &.uni-edit{  &::after { background-image: edit($orangerColor); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($orangerColor); } }
        &.uni-plus{ &::after { background-image: plus($orangerColor); } }
        &.uni-upload{ &::after { background-image: upload($orangerColor); } }
        &.uni-calendar{ &::after { background-image: calendar($orangerColor); } }
        &.uni-config{ &::after { background-image: config($orangerColor); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($orangerColor); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($orangerColor); } }
        &.uni-close{ &::after { background-image: close($orangerColor); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($orangerColor); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($orangerColor); } }
        &.uni-envelope{  &::after { background-image: envelope($orangerColor); } }
        &.uni-reload{ &::after { background-image: reload($orangerColor); } }
        &.uni-config-table{  &::after { background-image: configTable($orangerColor); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($orangerColor); } }
        &.uni-options{ &::after { background-image: options($orangerColor); }}
        &.uni-search{ &::after { background-image: search($orangerColor); } }
        &.uni-save{ &::after { background-image: save($orangerColor); } }
        &.uni-create-contract{ &::after { background-image: createContract($orangerColor); } }
        &.uni-work-off{  &::after { background-image: workOff($orangerColor); } }
        &.uni-shuffle{ &::after { background-image: shuffle($orangerColor); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($orangerColor); } }
        &.uni-import{  &::after { background-image: import($orangerColor); } }
        &.uni-caculator{ &::after { background-image: caculator($orangerColor); } }
        &.uni-pen-line{ &::after { background-image: penLine($orangerColor); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($orangerColor); } }
        &.uni-document-note{ &::after { background-image: documentNote($orangerColor); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($orangerColor); } }
        &.uni-refresh{ &::after { background-image: refresh($orangerColor); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($orangerColor); } }
        &.uni-update{ &::after { background-image: update($orangerColor); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($orangerColor); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($orangerColor); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($orangerColor); } }
        &.uni-copy-line{ &::after { background-image: copyLine($orangerColor); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($orangerColor); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($orangerColor); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($orangerColor); } }
        &.uni-folder{ &::after { background-image: folder($orangerColor); } }
        &.uni-phone{ &::after { background-image: phone($orangerColor); } }
        &.uni-door{ &::after { background-image: door($orangerColor); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($orangerColor); } }
        &.uni-clock{ &::after { background-image: clock($orangerColor); } }
        &.uni-camera{ &::after { background-image: camera($orangerColor); } }
        &.uni-send{ &::after { background-image: send($orangerColor); } }
        &.uni-document-money{ &::after { background-image: documentMoney($orangerColor); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($orangerColor); } }
        &.uni-vector{ &::after { background-image: vector($orangerColor); } }
        &.uni-more{ &::after { background-image: more($orangerColor); } }
        &.uni-table{&::after { background-image: tableGrid($orangerColor); }}
        &.uni-language{  &::after { background-image: language($orangerColor); }}
        &.uni-work{  &::after { background-image: work($orangerColor); }}
        &.uni-dots{  &::after { background-image: dots($orangerColor); }}
        &.uni-export{  &::after { background-image: export($orangerColor); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($orangerColor); }}
        &.uni-connect{  &::after { background-image: connect($orangerColor); }}
        &.uni-check-fill{  &::after { background-image: checkFill($orangerColor); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($orangerColor); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($orangerColor); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($orangerColor); }}
        &.uni-note-fill{  &::after { background-image: noteFill($orangerColor); }}
        &.uni-language-fill{  &::after { background-image: languageFill($orangerColor); }}

    }
    &.uni-red-color{
        &.uni-overview{ &::after { background-image: overview($redColor); }}
        &.uni-users{ &::after { background-image: users($redColor); } }
        &.uni-calendar{ &::after { background-image: calendar($redColor); } }
        &.uni-note-money{ &::after { background-image: noteMoney($redColor); } }
        &.uni-setting{ &::after { background-image: setting($redColor); } }
        &.uni-user-rate{ &::after { background-image: userRate($redColor); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($redColor); } }
        &.uni-note{ &::after { background-image: note($redColor); } }
        &.uni-note-user{ &::after { background-image: noteUser($redColor); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($redColor); } }
        &.uni-money{  &::after { background-image: money($redColor); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($redColor); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($redColor); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($redColor); } }
        &.uni-setting{ &::after { background-image: getSetting($redColor); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($redColor); } }
        &.uni-home{ &::after { background-image: home($redColor); } }
        &.uni-user{ &::after { background-image: user($redColor); } }
        &.uni-noti{ &::after { background-image: noti($redColor); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($redColor); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($redColor); } }
        &.uni-protect{ &::after { background-image: protect($redColor); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($redColor); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($redColor); } }
        &.uni-delete{ &::after { background-image: delete($redColor); } }
        &.uni-edit{  &::after { background-image: edit($redColor); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($redColor); } }
        &.uni-plus{ &::after { background-image: plus($redColor); } }
        &.uni-upload{ &::after { background-image: upload($redColor); } }
        &.uni-calendar{ &::after { background-image: calendar($redColor); } }
        &.uni-config{ &::after { background-image: config($redColor); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($redColor); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($redColor); } }
        &.uni-close{ &::after { background-image: close($redColor); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($redColor); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($redColor); } }
        &.uni-envelope{  &::after { background-image: envelope($redColor); } }
        &.uni-reload{ &::after { background-image: reload($redColor); } }
        &.uni-config-table{  &::after { background-image: configTable($redColor); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($redColor); } }
        &.uni-options{ &::after { background-image: options($redColor); }}
        &.uni-search{ &::after { background-image: search($redColor); } }
        &.uni-save{ &::after { background-image: save($redColor); } }
        &.uni-create-contract{ &::after { background-image: createContract($redColor); } }
        &.uni-work-off{  &::after { background-image: workOff($redColor); } }
        &.uni-shuffle{ &::after { background-image: shuffle($redColor); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($redColor); } }
        &.uni-import{  &::after { background-image: import($redColor); } }
        &.uni-caculator{ &::after { background-image: caculator($redColor); } }
        &.uni-pen-line{ &::after { background-image: penLine($redColor); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($redColor); } }
        &.uni-document-note{ &::after { background-image: documentNote($redColor); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($redColor); } }
        &.uni-refresh{ &::after { background-image: refresh($redColor); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($redColor); } }
        &.uni-update{ &::after { background-image: update($redColor); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($redColor); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($redColor); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($redColor); } }
        &.uni-copy-line{ &::after { background-image: copyLine($redColor); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($redColor); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($redColor); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($redColor); } }
        &.uni-folder{ &::after { background-image: folder($redColor); } }
        &.uni-phone{ &::after { background-image: phone($redColor); } }
        &.uni-door{ &::after { background-image: door($redColor); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($redColor); } }
        &.uni-clock{ &::after { background-image: clock($redColor); } }
        &.uni-camera{ &::after { background-image: camera($redColor); } }
        &.uni-send{ &::after { background-image: send($redColor); } }
        &.uni-document-money{ &::after { background-image: documentMoney($redColor); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($redColor); } }
        &.uni-vector{ &::after { background-image: vector($redColor); } }
        &.uni-more{ &::after { background-image: more($redColor); } }
        &.uni-table{&::after { background-image: tableGrid($redColor); }}
        &.uni-language{  &::after { background-image: language($redColor); }}
        &.uni-work{  &::after { background-image: work($redColor); }}
        &.uni-dots{  &::after { background-image: dots($redColor); }}
        &.uni-export{  &::after { background-image: export($redColor); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($redColor); }}
        &.uni-connect{  &::after { background-image: connect($redColor); }}
        &.uni-check-fill{  &::after { background-image: checkFill($redColor); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($redColor); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($redColor); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($redColor); }}
        &.uni-note-fill{  &::after { background-image: noteFill($redColor); }}
        &.uni-language-fill{  &::after { background-image: languageFill($redColor); }}

    }
    &.uni-green-color{
        &.uni-vector{ &::after { background-image: vector($greenColor); } }
    }
}


.uni-icon{
    &.uni-white-color{
        &.uni-overview{ &::after { background-image: overview($whiteColor); }}
        &.uni-users{ &::after { background-image: users($whiteColor); } }
        &.uni-calendar{ &::after { background-image: calendar($whiteColor); } }
        &.uni-note-money{ &::after { background-image: noteMoney($whiteColor); } }
        &.uni-setting{ &::after { background-image: setting($whiteColor); } }
        &.uni-user-rate{ &::after { background-image: userRate($whiteColor); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($whiteColor); } }
        &.uni-note{ &::after { background-image: note($whiteColor); } }
        &.uni-note-user{ &::after { background-image: noteUser($whiteColor); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($whiteColor); } }
        &.uni-money{  &::after { background-image: money($whiteColor); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($whiteColor); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($whiteColor); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($whiteColor); } }
        &.uni-setting{ &::after { background-image: getSetting($whiteColor); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($whiteColor); } }
        &.uni-home{ &::after { background-image: home($whiteColor); } }
        &.uni-user{ &::after { background-image: user($whiteColor); } }
        &.uni-noti{ &::after { background-image: noti($whiteColor); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($whiteColor); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($whiteColor); } }
        &.uni-protect{ &::after { background-image: protect($whiteColor); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($whiteColor); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($whiteColor); } }
        &.uni-delete{ &::after { background-image: delete($whiteColor); } }
        &.uni-edit{  &::after { background-image: edit($whiteColor); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($whiteColor); } }
        &.uni-plus{ &::after { background-image: plus($whiteColor); } }
        &.uni-upload{ &::after { background-image: upload($whiteColor); } }
        &.uni-calendar{ &::after { background-image: calendar($whiteColor); } }
        &.uni-config{ &::after { background-image: config($whiteColor); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($whiteColor); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($whiteColor); } }
        &.uni-close{ &::after { background-image: close($whiteColor); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($whiteColor); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($whiteColor); } }
        &.uni-envelope{  &::after { background-image: envelope($whiteColor); } }
        &.uni-reload{ &::after { background-image: reload($whiteColor); } }
        &.uni-config-table{  &::after { background-image: configTable($whiteColor); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($whiteColor); } }
        &.uni-options{ &::after { background-image: options($whiteColor); }}
        &.uni-search{ &::after { background-image: search($whiteColor); } }
        &.uni-save{ &::after { background-image: save($whiteColor); } }
        &.uni-create-contract{ &::after { background-image: createContract($whiteColor); } }
        &.uni-work-off{  &::after { background-image: workOff($whiteColor); } }
        &.uni-shuffle{ &::after { background-image: shuffle($whiteColor); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($whiteColor); } }
        &.uni-import{  &::after { background-image: import($whiteColor); } }
        &.uni-caculator{ &::after { background-image: caculator($whiteColor); } }
        &.uni-pen-line{ &::after { background-image: penLine($whiteColor); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($whiteColor); } }
        &.uni-document-note{ &::after { background-image: documentNote($whiteColor); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($whiteColor); } }
        &.uni-refresh{ &::after { background-image: refresh($whiteColor); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($whiteColor); } }
        &.uni-update{ &::after { background-image: update($whiteColor); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($whiteColor); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($whiteColor); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($whiteColor); } }
        &.uni-copy-line{ &::after { background-image: copyLine($whiteColor); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($whiteColor); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($whiteColor); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($whiteColor); } }
        &.uni-folder{ &::after { background-image: folder($whiteColor); } }
        &.uni-phone{ &::after { background-image: phone($whiteColor); } }
        &.uni-door{ &::after { background-image: door($whiteColor); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($whiteColor); } }
        &.uni-clock{ &::after { background-image: clock($whiteColor); } }
        &.uni-camera{ &::after { background-image: camera($whiteColor); } }
        &.uni-send{ &::after { background-image: send($whiteColor); } }
        &.uni-document-money{ &::after { background-image: documentMoney($whiteColor); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($whiteColor); } }
        &.uni-vector{ &::after { background-image: vector($whiteColor); } }
        &.uni-more{ &::after { background-image: more($whiteColor); } }
        &.uni-table{&::after { background-image: tableGrid($whiteColor); }}
        &.uni-language{  &::after { background-image: language($whiteColor); }}
        &.uni-work{  &::after { background-image: work($whiteColor); }}
        &.uni-dots{  &::after { background-image: dots($whiteColor); }}
        &.uni-export{  &::after { background-image: export($whiteColor); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($whiteColor); }}
        &.uni-connect{  &::after { background-image: connect($whiteColor); }}
        &.uni-check-fill{  &::after { background-image: checkFill($whiteColor); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($whiteColor); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($whiteColor); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($whiteColor); }}
        &.uni-note-fill{  &::after { background-image: noteFill($whiteColor); }}
        &.uni-language-fill{  &::after { background-image: languageFill($whiteColor); }}

    }
}
main.vertical{
  .p-panelmenu-panel{
    .p-panelmenu-header-link{
      .uni-icon{
        &.uni-white-color{
          &.uni-overview{ &::after { background-image: overview($colorIcon); }}
          &.uni-users{ &::after { background-image: users($colorIcon); } }
          &.uni-calendar{ &::after { background-image: calendar($colorIcon); } }
          &.uni-note-money{ &::after { background-image: noteMoney($colorIcon); } }
          &.uni-setting{ &::after { background-image: setting($colorIcon); } }
          &.uni-user-rate{ &::after { background-image: userRate($colorIcon); } }
          &.uni-home-building{ &::after { background-image: homeBuilding($colorIcon); } }
          &.uni-note{ &::after { background-image: note($colorIcon); } }
          &.uni-note-user{ &::after { background-image: noteUser($colorIcon); } }
          &.uni-learning-tools{ &::after { background-image: learningTools($colorIcon); } }
          &.uni-money{  &::after { background-image: money($colorIcon); } }
          &.uni-user-group{ &::after { background-image: getUserGroup($colorIcon); } }
          &.uni-user-plus{ &::after { background-image: getUserPlus($colorIcon); } }
          &.uni-user-ban{ &::after { background-image: getUserBan($colorIcon); } }
          &.uni-setting{ &::after { background-image: getSetting($colorIcon); } }
          &.uni-full-screen{ &::after { background-image: getFullScreen($colorIcon); } }
          &.uni-home{ &::after { background-image: home($colorIcon); } }
          &.uni-user{ &::after { background-image: user($colorIcon); } }
          &.uni-noti{ &::after { background-image: noti($colorIcon); } }
          &.uni-note-checked{ &::after { background-image: noteChecked($colorIcon); } }
          &.uni-money-cicle{ &::after { background-image: moneyCicle($colorIcon); } }
          &.uni-protect{ &::after { background-image: protect($colorIcon); } }
          &.uni-setting-transparent{ &::after { background-image: settingTransparent($colorIcon); } }
          &.uni-narrow-down{ &::after { background-image: narrowDown($colorIcon); } }
          &.uni-delete{ &::after { background-image: delete($colorIcon); } }
          &.uni-edit{  &::after { background-image: edit($colorIcon); } }
          &.uni-plus-cicle{ &::after { background-image: plusCicle($colorIcon); } }
          &.uni-plus{ &::after { background-image: plus($colorIcon); } }
          &.uni-upload{ &::after { background-image: upload($colorIcon); } }
          &.uni-calendar{ &::after { background-image: calendar($colorIcon); } }
          &.uni-config{ &::after { background-image: config($colorIcon); } }
          &.uni-cancel-cicle{ &::after { background-image: cancelCicle($colorIcon); } }
          &.uni-checked-cicle{ &::after { background-image: checkedCicle($colorIcon); } }
          &.uni-close{ &::after { background-image: close($colorIcon); } }
          &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($colorIcon); } }
          &.uni-file-attach{ &::after { background-image: fileAttach($colorIcon); } }
          &.uni-envelope{  &::after { background-image: envelope($colorIcon); } }
          &.uni-reload{ &::after { background-image: reload($colorIcon); } }
          &.uni-config-table{  &::after { background-image: configTable($colorIcon); } }
          &.uni-warning-cicle{ &::after { background-image: warningCicle($colorIcon); } }
          &.uni-options{ &::after { background-image: options($colorIcon); }}
          &.uni-search{ &::after { background-image: search($colorIcon); } }
          &.uni-save{ &::after { background-image: save($colorIcon); } }
          &.uni-create-contract{ &::after { background-image: createContract($colorIcon); } }
          &.uni-work-off{  &::after { background-image: workOff($colorIcon); } }
          &.uni-shuffle{ &::after { background-image: shuffle($colorIcon); } }
          &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($colorIcon); } }
          &.uni-import{  &::after { background-image: import($colorIcon); } }
          &.uni-caculator{ &::after { background-image: caculator($colorIcon); } }
          &.uni-pen-line{ &::after { background-image: penLine($colorIcon); } }
          &.uni-check-square-line{ &::after { background-image: checkSquareLine($colorIcon); } }
          &.uni-document-note{ &::after { background-image: documentNote($colorIcon); } }
          &.uni-structure-fill{ &::after { background-image: structureFill($colorIcon); } }
          &.uni-refresh{ &::after { background-image: refresh($colorIcon); } }
          &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($colorIcon); } }
          &.uni-update{ &::after { background-image: update($colorIcon); } }
          &.uni-note-basic{ &::after { background-image: noteBasic($colorIcon); } }
          &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($colorIcon); } }
          &.uni-lock-fill{ &::after { background-image: lockFill($colorIcon); } }
          &.uni-copy-line{ &::after { background-image: copyLine($colorIcon); } }
          &.uni-gmail-line{ &::after { background-image: gmailLine($colorIcon); } }
          &.uni-icon-square{ &::after { background-image: iconSquare($colorIcon); } }
          &.uni-chevron-right{ &::after { background-image: chevronRight($colorIcon); } }
          &.uni-folder{ &::after { background-image: folder($colorIcon); } }
          &.uni-phone{ &::after { background-image: phone($colorIcon); } }
          &.uni-door{ &::after { background-image: door($colorIcon); } }
          &.uni-notify-fill{ &::after { background-image: notifyFill($colorIcon); } }
          &.uni-clock{ &::after { background-image: clock($colorIcon); } }
          &.uni-camera{ &::after { background-image: camera($colorIcon); } }
          &.uni-send{ &::after { background-image: send($colorIcon); } }
          &.uni-document-money{ &::after { background-image: documentMoney($colorIcon); } }
          &.uni-caculator-fill{ &::after { background-image: caculatorFill($colorIcon); } }
          &.uni-vector{ &::after { background-image: vector($colorIcon); } }
          &.uni-more{ &::after { background-image: more($colorIcon); } }
          &.uni-table{&::after { background-image: tableGrid($colorIcon); }}
          &.uni-language{  &::after { background-image: language($colorIcon); }}
          &.uni-work{  &::after { background-image: work($colorIcon); }}
          &.uni-dots{  &::after { background-image: dots($colorIcon); }}
          &.uni-export{  &::after { background-image: export($colorIcon); }}
          &.uni-contact-fill{  &::after { background-image: contactFill($colorIcon); }}
          &.uni-connect{  &::after { background-image: connect($colorIcon); }}
          &.uni-check-fill{  &::after { background-image: checkFill($colorIcon); }}
          &.uni-money-fill{  &::after { background-image: moneyFill($colorIcon); }}
          &.uni-user-contact-fill{  &::after { background-image: userContactFill($colorIcon); }}
          &.uni-user-setting-fill{  &::after { background-image: userSettingFill($colorIcon); }}
          &.uni-note-fill{  &::after { background-image: noteFill($colorIcon); }}
          &.uni-language-fill{  &::after { background-image: languageFill($colorIcon); }}

        }
      }
    }
  }
}
.uni-icon{
  &.uni-white-color{
    &.uni-overview{ &::after { background-image: overview($whiteColor); }}
    &.uni-users{ &::after { background-image: users($whiteColor); } }
    &.uni-calendar{ &::after { background-image: calendar($whiteColor); } }
    &.uni-note-money{ &::after { background-image: noteMoney($whiteColor); } }
    &.uni-setting{ &::after { background-image: setting($whiteColor); } }
    &.uni-user-rate{ &::after { background-image: userRate($whiteColor); } }
    &.uni-home-building{ &::after { background-image: homeBuilding($whiteColor); } }
    &.uni-note{ &::after { background-image: note($whiteColor); } }
    &.uni-note-user{ &::after { background-image: noteUser($whiteColor); } }
    &.uni-learning-tools{ &::after { background-image: learningTools($whiteColor); } }
    &.uni-money{  &::after { background-image: money($whiteColor); } }
    &.uni-user-group{ &::after { background-image: getUserGroup($whiteColor); } }
    &.uni-user-plus{ &::after { background-image: getUserPlus($whiteColor); } }
    &.uni-user-ban{ &::after { background-image: getUserBan($whiteColor); } }
    &.uni-setting{ &::after { background-image: getSetting($whiteColor); } }
    &.uni-full-screen{ &::after { background-image: getFullScreen($whiteColor); } }
    &.uni-home{ &::after { background-image: home($whiteColor); } }
    &.uni-user{ &::after { background-image: user($whiteColor); } }
    &.uni-noti{ &::after { background-image: noti($whiteColor); } }
    &.uni-note-checked{ &::after { background-image: noteChecked($whiteColor); } }
    &.uni-money-cicle{ &::after { background-image: moneyCicle($whiteColor); } }
    &.uni-protect{ &::after { background-image: protect($whiteColor); } }
    &.uni-setting-transparent{ &::after { background-image: settingTransparent($whiteColor); } }
    &.uni-narrow-down{ &::after { background-image: narrowDown($whiteColor); } }
    &.uni-delete{ &::after { background-image: delete($whiteColor); } }
    &.uni-edit{  &::after { background-image: edit($whiteColor); } }
    &.uni-plus-cicle{ &::after { background-image: plusCicle($whiteColor); } }
    &.uni-plus{ &::after { background-image: plus($whiteColor); } }
    &.uni-upload{ &::after { background-image: upload($whiteColor); } }
    &.uni-calendar{ &::after { background-image: calendar($whiteColor); } }
    &.uni-config{ &::after { background-image: config($whiteColor); } }
    &.uni-cancel-cicle{ &::after { background-image: cancelCicle($whiteColor); } }
    &.uni-checked-cicle{ &::after { background-image: checkedCicle($whiteColor); } }
    &.uni-close{ &::after { background-image: close($whiteColor); } }
    &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($whiteColor); } }
    &.uni-file-attach{ &::after { background-image: fileAttach($whiteColor); } }
    &.uni-envelope{  &::after { background-image: envelope($whiteColor); } }
    &.uni-reload{ &::after { background-image: reload($whiteColor); } }
    &.uni-config-table{  &::after { background-image: configTable($whiteColor); } }
    &.uni-warning-cicle{ &::after { background-image: warningCicle($whiteColor); } }
    &.uni-options{ &::after { background-image: options($whiteColor); }}
    &.uni-search{ &::after { background-image: search($whiteColor); } }
    &.uni-save{ &::after { background-image: save($whiteColor); } }
    &.uni-create-contract{ &::after { background-image: createContract($whiteColor); } }
    &.uni-work-off{  &::after { background-image: workOff($whiteColor); } }
    &.uni-shuffle{ &::after { background-image: shuffle($whiteColor); } }
    &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($whiteColor); } }
    &.uni-import{  &::after { background-image: import($whiteColor); } }
    &.uni-caculator{ &::after { background-image: caculator($whiteColor); } }
    &.uni-pen-line{ &::after { background-image: penLine($whiteColor); } }
    &.uni-check-square-line{ &::after { background-image: checkSquareLine($whiteColor); } }
    &.uni-document-note{ &::after { background-image: documentNote($whiteColor); } }
    &.uni-structure-fill{ &::after { background-image: structureFill($whiteColor); } }
    &.uni-refresh{ &::after { background-image: refresh($whiteColor); } }
    &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($whiteColor); } }
    &.uni-update{ &::after { background-image: update($whiteColor); } }
    &.uni-note-basic{ &::after { background-image: noteBasic($whiteColor); } }
    &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($whiteColor); } }
    &.uni-lock-fill{ &::after { background-image: lockFill($whiteColor); } }
    &.uni-copy-line{ &::after { background-image: copyLine($whiteColor); } }
    &.uni-gmail-line{ &::after { background-image: gmailLine($whiteColor); } }
    &.uni-icon-square{ &::after { background-image: iconSquare($whiteColor); } }
    &.uni-chevron-right{ &::after { background-image: chevronRight($whiteColor); } }
    &.uni-folder{ &::after { background-image: folder($whiteColor); } }
    &.uni-phone{ &::after { background-image: phone($whiteColor); } }
    &.uni-door{ &::after { background-image: door($whiteColor); } }
    &.uni-notify-fill{ &::after { background-image: notifyFill($whiteColor); } }
    &.uni-clock{ &::after { background-image: clock($whiteColor); } }
    &.uni-camera{ &::after { background-image: camera($whiteColor); } }
    &.uni-send{ &::after { background-image: send($whiteColor); } }
    &.uni-document-money{ &::after { background-image: documentMoney($whiteColor); } }
    &.uni-caculator-fill{ &::after { background-image: caculatorFill($whiteColor); } }
    &.uni-vector{ &::after { background-image: vector($whiteColor); } }
    &.uni-more{ &::after { background-image: more($whiteColor); } }
    &.uni-table{&::after { background-image: tableGrid($whiteColor); }}
    &.uni-language{  &::after { background-image: language($whiteColor); }}
    &.uni-work{  &::after { background-image: work($whiteColor); }}
    &.uni-dots{  &::after { background-image: dots($whiteColor); }}
    &.uni-export{  &::after { background-image: export($whiteColor); }}
    &.uni-contact-fill{  &::after { background-image: contactFill($whiteColor); }}
    &.uni-connect{  &::after { background-image: connect($whiteColor); }}
    &.uni-check-fill{  &::after { background-image: checkFill($whiteColor); }}
    &.uni-money-fill{  &::after { background-image: moneyFill($whiteColor); }}
    &.uni-user-contact-fill{  &::after { background-image: userContactFill($whiteColor); }}
    &.uni-user-setting-fill{  &::after { background-image: userSettingFill($whiteColor); }}
    &.uni-note-fill{  &::after { background-image: noteFill($whiteColor); }}
    &.uni-language-fill{  &::after { background-image: languageFill($whiteColor); }}

  }
}
.uni-icon{
    &.uni-blue-color{
        &.uni-overview{ &::after { background-image: overview($IconHover); }}
        &.uni-users{ &::after { background-image: users($IconHover); } }
        &.uni-calendar{ &::after { background-image: calendar($IconHover); } }
        &.uni-note-money{ &::after { background-image: noteMoney($IconHover); } }
        &.uni-setting{ &::after { background-image: setting($IconHover); } }
        &.uni-user-rate{ &::after { background-image: userRate($IconHover); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($IconHover); } }
        &.uni-note{ &::after { background-image: note($IconHover); } }
        &.uni-note-user{ &::after { background-image: noteUser($IconHover); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($IconHover); } }
        &.uni-money{  &::after { background-image: money($IconHover); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($IconHover); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($IconHover); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($IconHover); } }
        &.uni-setting{ &::after { background-image: getSetting($IconHover); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($IconHover); } }
        &.uni-home{ &::after { background-image: home($IconHover); } }
        &.uni-user{ &::after { background-image: user($IconHover); } }
        &.uni-noti{ &::after { background-image: noti($IconHover); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($IconHover); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($IconHover); } }
        &.uni-protect{ &::after { background-image: protect($IconHover); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($IconHover); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($IconHover); } }
        &.uni-delete{ &::after { background-image: delete($IconHover); } }
        &.uni-edit{  &::after { background-image: edit($IconHover); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($IconHover); } }
        &.uni-plus{ &::after { background-image: plus($IconHover); } }
        &.uni-upload{ &::after { background-image: upload($IconHover); } }
        &.uni-calendar{ &::after { background-image: calendar($IconHover); } }
        &.uni-config{ &::after { background-image: config($IconHover); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($IconHover); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($IconHover); } }
        &.uni-close{ &::after { background-image: close($IconHover); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($IconHover); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($IconHover); } }
        &.uni-envelope{  &::after { background-image: envelope($IconHover); } }
        &.uni-reload{ &::after { background-image: reload($IconHover); } }
        &.uni-config-table{  &::after { background-image: configTable($IconHover); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($IconHover); } }
        &.uni-options{ &::after { background-image: options($IconHover); }}
        &.uni-search{ &::after { background-image: search($IconHover); } }
        &.uni-save{ &::after { background-image: save($IconHover); } }
        &.uni-create-contract{ &::after { background-image: createContract($IconHover); } }
        &.uni-work-off{  &::after { background-image: workOff($IconHover); } }
        &.uni-shuffle{ &::after { background-image: shuffle($IconHover); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($IconHover); } }
        &.uni-import{  &::after { background-image: import($IconHover); } }
        &.uni-caculator{ &::after { background-image: caculator($IconHover); } }
        &.uni-pen-line{ &::after { background-image: penLine($IconHover); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($IconHover); } }
        &.uni-document-note{ &::after { background-image: documentNote($IconHover); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($IconHover); } }
        &.uni-refresh{ &::after { background-image: refresh($IconHover); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($IconHover); } }
        &.uni-update{ &::after { background-image: update($IconHover); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($IconHover); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($IconHover); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($IconHover); } }
        &.uni-copy-line{ &::after { background-image: copyLine($IconHover); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($IconHover); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($IconHover); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($IconHover); } }
        &.uni-folder{ &::after { background-image: folder($IconHover); } }
        &.uni-phone{ &::after { background-image: phone($IconHover); } }
        &.uni-door{ &::after { background-image: door($IconHover); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($IconHover); } }
        &.uni-clock{ &::after { background-image: clock($IconHover); } }
        &.uni-camera{ &::after { background-image: camera($IconHover); } }
        &.uni-send{ &::after { background-image: send($IconHover); } }
        &.uni-document-money{ &::after { background-image: documentMoney($IconHover); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($IconHover); } }
        &.uni-vector{ &::after { background-image: vector($IconHover); } }
        &.uni-more{ &::after { background-image: more($IconHover); } }
        &.uni-table{&::after { background-image: tableGrid($IconHover); }}
        &.uni-language{  &::after { background-image: language($IconHover); }}
        &.uni-work{  &::after { background-image: work($IconHover); }}
        &.uni-dots{  &::after { background-image: dots($IconHover); }}
        &.uni-export{  &::after { background-image: export($IconHover); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($IconHover); }}
        &.uni-connect{  &::after { background-image: connect($IconHover); }}
        &.uni-check-fill{  &::after { background-image: checkFill($IconHover); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($IconHover); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($IconHover); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($IconHover); }}
        &.uni-note-fill{  &::after { background-image: noteFill($IconHover); }}
        &.uni-language-fill{  &::after { background-image: languageFill($IconHover); }}
    }
}
.uni-black-color{
    .uni-icon{
        &.uni-overview{ &::after { background-image: overview($blackColor); }}
        &.uni-users{ &::after { background-image: users($blackColor); } }
        &.uni-calendar{ &::after { background-image: calendar($blackColor); } }
        &.uni-note-money{ &::after { background-image: noteMoney($blackColor); } }
        &.uni-setting{ &::after { background-image: setting($blackColor); } }
        &.uni-user-rate{ &::after { background-image: userRate($blackColor); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($blackColor); } }
        &.uni-note{ &::after { background-image: note($blackColor); } }
        &.uni-note-user{ &::after { background-image: noteUser($blackColor); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($blackColor); } }
        &.uni-money{  &::after { background-image: money($blackColor); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($blackColor); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($blackColor); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($blackColor); } }
        &.uni-setting{ &::after { background-image: getSetting($blackColor); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($blackColor); } }
        &.uni-home{ &::after { background-image: home($blackColor); } }
        &.uni-user{ &::after { background-image: user($blackColor); } }
        &.uni-noti{ &::after { background-image: noti($blackColor); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($blackColor); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($blackColor); } }
        &.uni-protect{ &::after { background-image: protect($blackColor); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($blackColor); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($blackColor); } }
        &.uni-delete{ &::after { background-image: delete($blackColor); } }
        &.uni-edit{  &::after { background-image: edit($blackColor); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($blackColor); } }
        &.uni-plus{ &::after { background-image: plus($blackColor); } }
        &.uni-upload{ &::after { background-image: upload($blackColor); } }
        &.uni-calendar{ &::after { background-image: calendar($blackColor); } }
        &.uni-config{ &::after { background-image: config($blackColor); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($blackColor); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($blackColor); } }
        &.uni-close{ &::after { background-image: close($blackColor); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($blackColor); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($blackColor); } }
        &.uni-envelope{  &::after { background-image: envelope($blackColor); } }
        &.uni-reload{ &::after { background-image: reload($blackColor); } }
        &.uni-config-table{  &::after { background-image: configTable($blackColor); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($blackColor); } }
        &.uni-options{ &::after { background-image: options($blackColor); }}
        &.uni-search{ &::after { background-image: search($blackColor); } }
        &.uni-save{ &::after { background-image: save($blackColor); } }
        &.uni-create-contract{ &::after { background-image: createContract($blackColor); } }
        &.uni-work-off{  &::after { background-image: workOff($blackColor); } }
        &.uni-shuffle{ &::after { background-image: shuffle($blackColor); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($blackColor); } }
        &.uni-import{  &::after { background-image: import($blackColor); } }
        &.uni-caculator{ &::after { background-image: caculator($blackColor); } }
        &.uni-pen-line{ &::after { background-image: penLine($blackColor); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($blackColor); } }
        &.uni-document-note{ &::after { background-image: documentNote($blackColor); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($blackColor); } }
        &.uni-refresh{ &::after { background-image: refresh($blackColor); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($blackColor); } }
        &.uni-update{ &::after { background-image: update($blackColor); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($blackColor); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($blackColor); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($blackColor); } }
        &.uni-copy-line{ &::after { background-image: copyLine($blackColor); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($blackColor); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($blackColor); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($blackColor); } }
        &.uni-folder{ &::after { background-image: folder($blackColor); } }
        &.uni-phone{ &::after { background-image: phone($blackColor); } }
        &.uni-door{ &::after { background-image: door($blackColor); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($blackColor); } }
        &.uni-clock{ &::after { background-image: clock($blackColor); } }
        &.uni-camera{ &::after { background-image: camera($blackColor); } }
        &.uni-send{ &::after { background-image: send($blackColor); } }
        &.uni-document-money{ &::after { background-image: documentMoney($blackColor); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($blackColor); } }
        &.uni-vector{ &::after { background-image: vector($blackColor); } }
        &.uni-more{ &::after { background-image: more($blackColor); } }
        &.uni-table{&::after { background-image: tableGrid($blackColor); }}
        &.uni-language{  &::after { background-image: language($blackColor); }}
        &.uni-work{  &::after { background-image: work($blackColor); }}
        &.uni-dots{  &::after { background-image: dots($blackColor); }}
        &.uni-export{  &::after { background-image: export($blackColor); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($blackColor); }}
        &.uni-connect{  &::after { background-image: connect($blackColor); }}
        &.uni-check-fill{  &::after { background-image: checkFill($blackColor); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($blackColor); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($blackColor); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($blackColor); }}
        &.uni-note-fill{  &::after { background-image: noteFill($blackColor); }}
        &.uni-language-fill{  &::after { background-image: languageFill($blackColor); }}

    }
}
button:hover .uni-icon,
a:hover .uni-icon{
    &.uni-hover-orange{
        &.uni-overview{ &::after { background-image: overview($orangerColor); }}
        &.uni-users{ &::after { background-image: users($orangerColor); } }
        &.uni-calendar{ &::after { background-image: calendar($orangerColor); } }
        &.uni-note-money{ &::after { background-image: noteMoney($orangerColor); } }
        &.uni-setting{ &::after { background-image: setting($orangerColor); } }
        &.uni-user-rate{ &::after { background-image: userRate($orangerColor); } }
        &.uni-home-building{ &::after { background-image: homeBuilding($orangerColor); } }
        &.uni-note{ &::after { background-image: note($orangerColor); } }
        &.uni-note-user{ &::after { background-image: noteUser($orangerColor); } }
        &.uni-learning-tools{ &::after { background-image: learningTools($orangerColor); } }
        &.uni-money{  &::after { background-image: money($orangerColor); } }
        &.uni-user-group{ &::after { background-image: getUserGroup($orangerColor); } }
        &.uni-user-plus{ &::after { background-image: getUserPlus($orangerColor); } }
        &.uni-user-ban{ &::after { background-image: getUserBan($orangerColor); } }
        &.uni-setting{ &::after { background-image: getSetting($orangerColor); } }
        &.uni-full-screen{ &::after { background-image: getFullScreen($orangerColor); } }
        &.uni-home{ &::after { background-image: home($orangerColor); } }
        &.uni-user{ &::after { background-image: user($orangerColor); } }
        &.uni-noti{ &::after { background-image: noti($orangerColor); } }
        &.uni-note-checked{ &::after { background-image: noteChecked($orangerColor); } }
        &.uni-money-cicle{ &::after { background-image: moneyCicle($orangerColor); } }
        &.uni-protect{ &::after { background-image: protect($orangerColor); } }
        &.uni-setting-transparent{ &::after { background-image: settingTransparent($orangerColor); } }
        &.uni-narrow-down{ &::after { background-image: narrowDown($orangerColor); } }
        &.uni-delete{ &::after { background-image: delete($orangerColor); } }
        &.uni-edit{  &::after { background-image: edit($orangerColor); } }
        &.uni-plus-cicle{ &::after { background-image: plusCicle($orangerColor); } }
        &.uni-plus{ &::after { background-image: plus($orangerColor); } }
        &.uni-upload{ &::after { background-image: upload($orangerColor); } }
        &.uni-calendar{ &::after { background-image: calendar($orangerColor); } }
        &.uni-config{ &::after { background-image: config($orangerColor); } }
        &.uni-cancel-cicle{ &::after { background-image: cancelCicle($orangerColor); } }
        &.uni-checked-cicle{ &::after { background-image: checkedCicle($orangerColor); } }
        &.uni-close{ &::after { background-image: close($orangerColor); } }
        &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($orangerColor); } }
        &.uni-file-attach{ &::after { background-image: fileAttach($orangerColor); } }
        &.uni-envelope{  &::after { background-image: envelope($orangerColor); } }
        &.uni-reload{ &::after { background-image: reload($orangerColor); } }
        &.uni-config-table{  &::after { background-image: configTable($orangerColor); } }
        &.uni-warning-cicle{ &::after { background-image: warningCicle($orangerColor); } }
        &.uni-options{ &::after { background-image: options($orangerColor); }}
        &.uni-search{ &::after { background-image: search($orangerColor); } }
        &.uni-save{ &::after { background-image: save($orangerColor); } }
        &.uni-create-contract{ &::after { background-image: createContract($orangerColor); } }
        &.uni-work-off{  &::after { background-image: workOff($orangerColor); } }
        &.uni-shuffle{ &::after { background-image: shuffle($orangerColor); } }
        &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($orangerColor); } }
        &.uni-import{  &::after { background-image: import($orangerColor); } }
        &.uni-caculator{ &::after { background-image: caculator($orangerColor); } }
        &.uni-pen-line{ &::after { background-image: penLine($orangerColor); } }
        &.uni-check-square-line{ &::after { background-image: checkSquareLine($orangerColor); } }
        &.uni-document-note{ &::after { background-image: documentNote($orangerColor); } }
        &.uni-structure-fill{ &::after { background-image: structureFill($orangerColor); } }
        &.uni-refresh{ &::after { background-image: refresh($orangerColor); } }
        &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($orangerColor); } }
        &.uni-update{ &::after { background-image: update($orangerColor); } }
        &.uni-note-basic{ &::after { background-image: noteBasic($orangerColor); } }
        &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($orangerColor); } }
        &.uni-lock-fill{ &::after { background-image: lockFill($orangerColor); } }
        &.uni-copy-line{ &::after { background-image: copyLine($orangerColor); } }
        &.uni-gmail-line{ &::after { background-image: gmailLine($orangerColor); } }
        &.uni-icon-square{ &::after { background-image: iconSquare($orangerColor); } }
        &.uni-chevron-right{ &::after { background-image: chevronRight($orangerColor); } }
        &.uni-folder{ &::after { background-image: folder($orangerColor); } }
        &.uni-phone{ &::after { background-image: phone($orangerColor); } }
        &.uni-door{ &::after { background-image: door($orangerColor); } }
        &.uni-notify-fill{ &::after { background-image: notifyFill($orangerColor); } }
        &.uni-clock{ &::after { background-image: clock($orangerColor); } }
        &.uni-camera{ &::after { background-image: camera($orangerColor); } }
        &.uni-send{ &::after { background-image: send($orangerColor); } }
        &.uni-document-money{ &::after { background-image: documentMoney($orangerColor); } }
        &.uni-caculator-fill{ &::after { background-image: caculatorFill($orangerColor); } }
        &.uni-vector{ &::after { background-image: vector($orangerColor); } }
        &.uni-more{ &::after { background-image: more($orangerColor); } }
        &.uni-table{&::after { background-image: tableGrid($orangerColor); }}
        &.uni-language{  &::after { background-image: language($orangerColor); }}
        &.uni-work{  &::after { background-image: work($orangerColor); }}
        &.uni-dots{  &::after { background-image: dots($orangerColor); }}
        &.uni-export{  &::after { background-image: export($orangerColor); }}
        &.uni-contact-fill{  &::after { background-image: contactFill($orangerColor); }}
        &.uni-connect{  &::after { background-image: connect($orangerColor); }}
        &.uni-check-fill{  &::after { background-image: checkFill($orangerColor); }}
        &.uni-money-fill{  &::after { background-image: moneyFill($orangerColor); }}
        &.uni-user-contact-fill{  &::after { background-image: userContactFill($orangerColor); }}
        &.uni-user-setting-fill{  &::after { background-image: userSettingFill($orangerColor); }}
        &.uni-note-fill{  &::after { background-image: noteFill($orangerColor); }}
        &.uni-language-fill{  &::after { background-image: languageFill($orangerColor); }}
    }
}


.parent_active{
  .uni-icon{
    &.uni-overview{ &::after { background-image: overview($orangerColor) !important; }}
    &.uni-users{ &::after { background-image: users($orangerColor) !important; }}
    &.uni-calendar{ &::after { background-image: calendar($orangerColor) !important; }}
    &.uni-note-money{ &::after { background-image: noteMoney($orangerColor) !important; }}
    &.uni-setting{ &::after { background-image: setting($orangerColor) !important; }}
    &.uni-user-rate{ &::after { background-image: userRate($orangerColor) !important; }}
    &.uni-home-building{ &::after { background-image: homeBuilding($orangerColor) !important; }}
    &.uni-note{ &::after { background-image: note($orangerColor) !important; }}
    &.uni-note-user{ &::after { background-image: noteUser($orangerColor) !important; }}
    &.uni-learning-tools{ &::after { background-image: learningTools($orangerColor) !important; }}
    &.uni-money{  &::after { background-image: money($orangerColor) !important; }}
    &.uni-user-group{ &::after { background-image: getUserGroup($orangerColor) !important; }}
    &.uni-user-plus{ &::after { background-image: getUserPlus($orangerColor) !important; }}
    &.uni-user-ban{ &::after { background-image: getUserBan($orangerColor) !important; }}
    &.uni-setting{ &::after { background-image: getSetting($orangerColor) !important; }}
    &.uni-full-screen{ &::after { background-image: getFullScreen($orangerColor) !important; }}
    &.uni-home{ &::after { background-image: home($orangerColor) !important; }}
    &.uni-user{ &::after { background-image: user($orangerColor) !important; }}
    &.uni-noti{ &::after { background-image: noti($orangerColor) !important; }}
    &.uni-note-checked{ &::after { background-image: noteChecked($orangerColor) !important; }}
    &.uni-money-cicle{ &::after { background-image: moneyCicle($orangerColor) !important; }}
    &.uni-protect{ &::after { background-image: protect($orangerColor) !important; }}
    &.uni-setting-transparent{ &::after { background-image: settingTransparent($orangerColor) !important; }}
    &.uni-narrow-down{ &::after { background-image: narrowDown($orangerColor) !important; }}
    &.uni-delete{ &::after { background-image: delete($orangerColor) !important; }}
    &.uni-edit{  &::after { background-image: edit($orangerColor) !important; }}
    &.uni-plus-cicle{ &::after { background-image: plusCicle($orangerColor) !important; }}
    &.uni-plus{ &::after { background-image: plus($orangerColor) !important; }}
    &.uni-upload{ &::after { background-image: upload($orangerColor) !important; }}
    &.uni-calendar{ &::after { background-image: calendar($orangerColor) !important; }}
    &.uni-config{ &::after { background-image: config($orangerColor) !important; }}
    &.uni-cancel-cicle{ &::after { background-image: cancelCicle($orangerColor) !important; }}
    &.uni-checked-cicle{ &::after { background-image: checkedCicle($orangerColor) !important; }}
    &.uni-close{ &::after { background-image: close($orangerColor) !important; }}
    &.uni-narrow-long-left{ &::after { background-image: narrowLongLeft($orangerColor) !important; }}
    &.uni-file-attach{ &::after { background-image: fileAttach($orangerColor) !important; }}
    &.uni-envelope{  &::after { background-image: envelope($orangerColor) !important; }}
    &.uni-reload{ &::after { background-image: reload($orangerColor) !important; }}
    &.uni-config-table{  &::after { background-image: configTable($orangerColor) !important; }}
    &.uni-warning-cicle{ &::after { background-image: warningCicle($orangerColor) !important; }}
    &.uni-options{ &::after { background-image: options($orangerColor) !important; }}
    &.uni-search{ &::after { background-image: search($orangerColor) !important; }}
    &.uni-save{ &::after { background-image: save($orangerColor) !important; }}
    &.uni-create-contract{ &::after { background-image: createContract($orangerColor) !important; }}
    &.uni-work-off{  &::after { background-image: workOff($orangerColor) !important; }}
    &.uni-shuffle{ &::after { background-image: shuffle($orangerColor) !important; }}
    &.uni-exit-cicle-fill{ &::after { background-image: exitCicleFill($orangerColor) !important; }}
    &.uni-import{  &::after { background-image: import($orangerColor) !important; }}
    &.uni-caculator{ &::after { background-image: caculator($orangerColor) !important; }}
    &.uni-pen-line{ &::after { background-image: penLine($orangerColor) !important; }}
    &.uni-check-square-line{ &::after { background-image: checkSquareLine($orangerColor) !important; }}
    &.uni-document-note{ &::after { background-image: documentNote($orangerColor) !important; }}
    &.uni-structure-fill{ &::after { background-image: structureFill($orangerColor) !important; }}
    &.uni-refresh{ &::after { background-image: refresh($orangerColor) !important; }}
    &.uni-arrow-down-fill{ &::after { background-image: arrowDownFill($orangerColor) !important; }}
    &.uni-update{ &::after { background-image: update($orangerColor) !important; }}
    &.uni-note-basic{ &::after { background-image: noteBasic($orangerColor) !important; }}
    &.uni-lock-open-fill{ &::after { background-image: lockOpenFill($orangerColor) !important; }}
    &.uni-lock-fill{ &::after { background-image: lockFill($orangerColor) !important; }}
    &.uni-copy-line{ &::after { background-image: copyLine($orangerColor) !important; }}
    &.uni-gmail-line{ &::after { background-image: gmailLine($orangerColor) !important; }}
    &.uni-icon-square{ &::after { background-image: iconSquare($orangerColor) !important; }}
    &.uni-chevron-right{ &::after { background-image: chevronRight($orangerColor) !important; }}
    &.uni-folder{ &::after { background-image: folder($orangerColor) !important; }}
    &.uni-phone{ &::after { background-image: phone($orangerColor) !important; }}
    &.uni-door{ &::after { background-image: door($orangerColor) !important; }}
    &.uni-notify-fill{ &::after { background-image: notifyFill($orangerColor) !important; }}
    &.uni-clock{ &::after { background-image: clock($orangerColor) !important; }}
    &.uni-camera{ &::after { background-image: camera($orangerColor) !important; }}
    &.uni-send{ &::after { background-image: send($orangerColor) !important; }}
    &.uni-document-money{ &::after { background-image: documentMoney($orangerColor) !important; }}
    &.uni-caculator-fill{ &::after { background-image: caculatorFill($orangerColor) !important; }}
    &.uni-vector{ &::after { background-image: vector($orangerColor) !important; }}
    &.uni-more{ &::after { background-image: more($orangerColor) !important; }}
    &.uni-table{&::after { background-image: tableGrid($orangerColor) !important; }}
    &.uni-language{  &::after { background-image: language($orangerColor) !important; }}
    &.uni-work{  &::after { background-image: work($orangerColor) !important; }}
    &.uni-dots{  &::after { background-image: dots($orangerColor) !important; }}
    &.uni-export{  &::after { background-image: export($orangerColor) !important; }}
    &.uni-contact-fill{  &::after { background-image: contactFill($orangerColor) !important; }}
    &.uni-connect{  &::after { background-image: connect($orangerColor) !important; }}
    &.uni-check-fill{  &::after { background-image: checkFill($orangerColor) !important; }}
    &.uni-money-fill{  &::after { background-image: moneyFill($orangerColor) !important; }}
    &.uni-user-contact-fill{  &::after { background-image: userContactFill($orangerColor) !important; }}
    &.uni-user-setting-fill{  &::after { background-image: userSettingFill($orangerColor) !important; }}
    &.uni-note-fill{  &::after { background-image: noteFill($orangerColor) !important; }}
    &.uni-language-fill{  &::after { background-image: languageFill($orangerColor) !important; }}

  }
}
