@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
@font-face {
    font-family: 'SFProTextMedium';
    src: url('assets/fonts/SFProText-Medium.woff2') format('woff2'),
        url('assets/fonts/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SFProTextRegular';
    src: url('assets/fonts/SFProText-Regular.woff2') format('woff2'),
        url('assets/fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SFProTextRegular';
    src: url('assets/fonts/SFProText-Medium.woff2') format('woff2'),
        url('assets/fonts/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

$fontRegular: 'SFProTextRegular';

:root {
    --font-size: 13px;
    --font-family:'SFProTextRegular'
}
html {
    font-size: 1rem;
    height: 100%;
    font-family: 'SFProTextRegular';
    line-height: 20px;
}
.main-grid{
    padding-top: 0;
    .max-w-full {
        padding-top: 2px;
    }
    .tools{
        .p-button{
            padding: 10px
        }
    }
}
body {
    font-family: var(--font-family);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    min-height: calc(100% - 5px);
    height: calc( 100% - 5px );
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: var(--font-size);
    background: #EDF1FA;
    .p-component{
        font-size: var(--font-size);
    }
}

.p-link,
.p-component{
    font-family: $fontRegular
}
h1,
h2,
h3,
h4,
h5,
h6{
    margin: 0;
    font-weight: 500;
}
h3{
    font-size: 16px;
}
*{
    box-sizing: border-box;
    border: none;
    margin: 0px;
}
$C-212633: #212633;
$C-F3F8FF: #F3F8FF;
$C-FF3B49: #FF3B49;
$C-99A2BC: #99A2BC;
$C-f68c1f: #f68c1f;
$C-64748B: #64748B;
$C-475569: #475569;
$grey: #76809B;
$activeColor: orange;
$C-525B73 : #525B73;
$mainBg: #0a58ca;

$primary: #007bff;
$primary006: rgba(0, 123, 255, 0.06);

$secondary: #6c757d;
$secondary006: rgba(108, 117, 125, 0.06);

$success: #28a745;
$success006: rgba(40, 167, 70, 0.06);

$danger: #dc3545;
$danger006: rgba(220, 53, 70, 0.06);

$warning: #ffc10755;
$warning006: rgba(255, 193, 7, 0.06);

$info: #17a2b8;
$info006: rgba(23, 163, 184, 0.06);

$light: #f8f9fa;
$light006: rgba(248, 249, 250, 0.06);

$dark: #343a40;
$dark006: rgba(52, 58, 64, 0.06);

$inProgress: #0747A6;
$inProgress006: rgba(7, 71, 166, 0.06);

$white: #fff;
// border fields
$C-E2E6F2: #E2E6F2;
$fontColor: $C-212633;
$btnBg: #f37721;
@import "assets/layout/styles/layout/layout.scss";
.text-danger {
    color: red;
}
.w-100 {
    width: 100%;
}
.h-40 {
  height: 40px;
}

.p-organizationchart {
  .p-organizationchart-line-top,.p-organizationchart-line-left, .p-organizationchart-line-right{
    //border-color: rgb(79 0 0 / 90%) !important;
    border-color: #0091ea !important;
  }
}

.p-organizationchart-lines .p-organizationchart-line-down {
  background: #0091ea !important;
}

.p-paginator, .p-overlay-content {
  .p-dropdown-panel ul p-dropdownitem:last-child {
    li[aria-label="1000000000"] span::before {
      content: "All";
      visibility: visible;
    }
    li[aria-label="1000000000"] span::after {
      content: "";
    }
    li[aria-label="1000000000"] span {
      visibility: hidden;
    }

  }
}


.pointer {
  cursor: pointer;
}
.jtk-connector{
  &.jtk-hover{
    path{
      stroke: #000;
      stroke-width: 1.5px
    }
  }
}
//.checkbox {
//  .fields {
//    display: flex;
//    flex-direction: row-reverse;
//    gap: 10px;
//    justify-content: flex-end;
//  }
//}

.jtk-endpoint{
  opacity: 0;

}
//.jtk-endpoint {
//  &.jtk-endpoint-anchor{
//    &>svg{
//      opacity: 0.2;
//      width: 150px;
//      height: 50px;
//      background: #99cb3a;
//      transform: translate(calc(-50% + 10px), calc(-50% + 10px));
//      circle{
//        opacity: 0;
//      }
//    }
//    //&.dragActive,
//    &.jtk-dragging{
//      &>svg{
//        opacity: 1;
//        width: 20px;
//        height: 20px;
//        transform: none;
//        circle{
//          opacity: 1;
//        }
//      }
//    }
//  }
//  &:hover{
//    &.jtk-endpoint-anchor{
//      &>svg{
//        opacity: 1;
//      }
//    }
//  }
//}

.jtk-overlay {
  position: absolute;
  left: 637px;
  top: 283px;
  transform: translate(-50%, -50%) rotate(-90deg);
  margin-left: -10px;
  font-weight: 500;
  background: #fff !important;
  z-index: 10;
  padding: 0px 3px;
}
app-node-container {
  .node,
  .jtk-overlay::selection{
    background: transparent;
  }
  .node{
    box-shadow: none !important;

    &.bg-success{
      width: auto !important
    }
    &.border-round-all{
      padding-left: 4px;
      padding-right: 4px;
      height: 18px;
      line-height: 17px;
    }
  }
  .bg-primary{
    background: #0d6efd;
  }
  .bg-secondary {
    // background: #6c757d;
    // border: 1px solid #6c757d;
    // color: #6c757d;
    color: #ffffff;
    background: #6c757d;
    &::selection{
      background: #ffffff;
    }
    &:after {
      background: #6c757d;
    }
  }
  .bg-danger{
    // color: #c82333;
    background: #dc3545;
  }
  .bg-warning{
    background: #ffc107;
  }
  .bg-success{
    color: #ffffff;
    background: #198754;
    width: 100%;
    &::selection{
      background: #ffffff;
    }
  }
  .bg-info{
    color: #ffffff;
    background: #0dcaf0;
  }
  .bg-light{
    color: #212529;
    background: #f8f9fa;
  }

  .bg-dark{
    color: #ffffff;
    background: #212529;
  }

}

.ag-theme-balham{
  .ag-ltr {
    .ag-row-group-leaf-indent{
      margin-left: 0px !important;
    }
  }
}

.workflow-view {
  white-space: nowrap;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #e7e3e3;
  transition: none;
  border-radius: 0;
  &:hover {
    background: #9E9E9E;
  }
}

.p-tabview {
  app-node-container {
    .btn-popup-bottom-right {
      position: fixed;
      bottom: 20px;
      right: 20px;
    }
  }
}

.ngx-org {
  &-image {
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    background-color: white;
    border-radius: 50%;
    padding: 0.25em;
  }

  &-name {
    font-family: $fontRegular;
    font-weight: 500;
  }

  &-title {
    font-family: $fontRegular;
  }
}




.ngx-org {
  &-horizontal {
    padding: 1em 0;
  }

  &-vertical {
    padding: 0 1em;
  }

  &-border {
    border: 1px solid darkgrey;
  }

  &-box {
    padding: 0.5em 1em;
    border-radius: .2em;
    box-shadow: 0.05em 0.05em 0.2em 0.05em #0000002b;
  }

  &-connector {
    &-horizontal {
      width: 1em;
    }

    &-vertical {
      height: 1em;
    }
  }
}

.img{
  position: relative;
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100% !important;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  &:after{
    content: "";
    padding-top: 100%;
    display: block;
  }
}

.start {
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100% !important;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  &:after{
    content: "";
    padding-top: 100%;
    display: block;
  }
}
//.p-component{
//  .p-timeline-event{
//    display: block;
//  }
//}
//.custom-marker{
//  display: flex;
//  gap: 12px;
//  .info{
//    color: #525252;
//    i{
//      font-size: 6px;
//    }
//    .who{
//      font-weight: 500;
//    }
//  }
//  .dot{
//    width: 18px;
//    height: 18px;
//    border-radius: 100%;
//    border: 1px solid #ccc;
//    text-align: center;
//    line-height: 11px;
//    display: block;
//    i{
//      width: 4px;
//      height: 4px;
//      display: inline-block;
//      font-size: 100%;
//      overflow: hidden;
//      background: #000;
//      border-radius: 100%;
//      color: unset;
//      text-indent: -10px;
//    }
//  }
//}
.emp-comments{
  .actions{
    span{
      color: #ababab;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.p-tree {
  .p-tree-filter {
    &.p-inputtext {
      padding: 12px;
      border-color: #ccc;
    }
  }
  li {
    &.p-treenode {
      padding: 0 !important;
    }
  }
  .p-treenode-content {
    .p-tree-toggler {
      height: 1rem !important;
    }
  }
}

.hideFooter {
  .p-sidebar-footer {
    display: none;
  }
}

    child-avatar{
      .p-avatar-circle{
        width: 26px !important;
        height: 26px !important;
        font-size: 12px !important;
        text-align: center !important;
      }
      &>div{
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        .p-image{
          &>img{
            width: 26px;
            height: 26px;
            min-width: 26px;
            object-fit: cover;
            border-radius: 100%;
            border: 1px solid #e6e6e6;
            background-color: #e6e6e6;
          }
        }
        .noti-number{
          font-size: 10px;
          margin-top: -10px;
        }
      }
    }
.bread-crumb{
    p-selectbutton{
      .p-button{
          height: 34px;
          width: 34px;
          padding: 0px;
          line-height: 34px;
          text-align: center;
          justify-content: center;
          &:first-child{
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
          }
          &:last-child{
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
          }
          &:active,
          &.p-highlight{
              background: #0a58ca !important;
              color: #fff !important;
              &:hover{
                color: #fff !important;
                  background: #0a58ca !important;
              }
          }
      }
  }
}

.uni-calendar{
  .fc-header-toolbar{
    .fc-button-group{
      .fc-button{
        &.fc-prev-button{
          padding-right: 16px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border: 1px solid rgba(43, 47, 51, 0.1) !important;
          color: #4C97E4 !important;
          &:after{
            display: none;
          }
        }
      }
    }
    .fc-toolbar-chunk{
      .fc-today-button{
        background: #4C97E4 !important;
        color: #fff !important;
      }
    }
  }
}
.bg0d6efd .p-checkbox-box {
  background: #0d6efd !important;
  &.p-highlight{
    background: #0d6efd !important;
  }
}
.bg6c757d .p-checkbox-box {
  &.p-highlight{
    background: #6c757d !important;
  }
  background: #6c757d !important; }
.bg198754 .p-checkbox-box {
  &.p-highlight{
    background: #198754 !important;
  }
  background: #198754 !important;
}
.bgdc3545 .p-checkbox-box {
  &.p-highlight{
    background: #dc3545 !important;
  }
  background: #dc3545 !important;
}
.bgffc107 .p-checkbox-box {
  &.p-highlight{
    background: #ffc107 !important;
  }
  background: #ffc107 !important;
}
.bg0dcaf0 .p-checkbox-box {
  &.p-highlight{
    background: #0dcaf0 !important;
  }
  background: #0dcaf0 !important;
}

.fullheight100 {
  .p-tabview-nav {
    height: calc(100ch - 8px) !important;
  }
}

.p-accordion-tab {
  .btn-option {
    padding: 19px 12px !important;
  }
}

.sidebar-body {
  .btn-group-edit-detail {
    position: static !important;
  }
}

.p-accordion-content {
  .checkbox {
    .fields {
      display: flex !important;
      p-checkbox {
        display: flex !important;
        align-items: end !important;
        .p-checkbox {
          align-items: end !important;
          padding-bottom: 5px !important;
        }
      }
    }
  }
}

.popup-timeline{
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0px 0px 3px 0px #ccc !important;
  ul{
    max-height: 600px;
    overflow: auto;
    li{
      text-wrap: nowrap;
      position: relative;
      padding-left: 23px;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0px;
      }
      &:hover{
        cursor: pointer;
        background: #f3f3f3;
        border-radius: 3px;
      }
      &:after{
        content: "";
        width: 6px;
        height: 6px;
        border: 2px solid green;
        border-radius: 100%;
        position: absolute;
        left: 5px;
        top: 5px;
        display: block;
      }
    }
  }
}

.p-tag {
  &.bgDone{
    background-color: $success006 !important;
    border-color: $success006 !important;
    .p-tag-value{
      font-weight: 400;
      color: $success !important;
    }
  }
  &.bgTodo{
    background-color: $secondary006 !important;
    border-color: $secondary006 !important;
    .p-tag-value{
      font-weight: 400;
      color: $secondary !important;
    }
  }
  &.bgInprogress{
    background-color: $inProgress006 !important;
    border-color: $inProgress006 !important;
    .p-tag-value{
      font-weight: 400;
      color: $inProgress !important;
    }
  }
  &.bgUndo{
    background-color: $dark006 !important;
    border-color: $dark006 !important;
    .p-tag-value{
      font-weight: 400;
      color: $dark !important;
    }

  }
}


.status-emp{
  display: block;
  span{
    width: auto;
    display: inline;
  }
}

.img-avatar{
  .pi{
    color: #fff;
  }
}
.info-other-emp{
  text-align: center;
  ul{
    padding: 10px;
    li{
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }
  }
  &.edit {
    li {
      margin-bottom: 10px;
    }
    .card-priority {
      padding-bottom: 0px;
      ul {
        padding-bottom: 0px;
        li {
          &:last-child {
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}
.p-dropdown-items,
.p-dropdown{
  img{
    width: 25px !important;
    height: 25px !important;
    object-fit: cover;
    border-radius: 100%;
  }
  .p-avatar{
    &.p-avatar-circle{
      width: 25px;
      height: 25px;
      font-size: 100%;
      margin-right: 0px !important;
    }
  }
}

.p-overlaypanel {
  .p-multiselect {
    .p-multiselect-item {
      padding: 0.5rem 0.5rem !important;
      display: flex;
      align-items: center;
      .p-checkbox {
        display: flex;
        align-items: center;
      }
    }
  }
}

.button-grid {
  .p-menuitem {
    &:first-child {
        display: none;
    }
  }
}

.p-rating {
  .p-rating-icon {
    font-size: 1rem !important;
    &.pi-star-fill {
      color: #ffc107 !important;
    }
  }
}


.career{
  min-height: 100vh;
  background: #fff;
  header{
    padding: 15px 40px;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    position: sticky;
    top: 0px;
    z-index: 999;
    [class*=col]{
      padding: 0px;
    }
    .p-menubar{
      background: none;
      display: block;
      padding-top: 9px;
      ul.p-menubar-root-list{
        justify-content: end;
        &>li.p-menuitem{
          &>a.p-menuitem-link{
            span.p-menuitem-text{
              color: #76809B !important;
              font-size: 16px;
            }
            &:hover{
              span{
                background: var(--BG-Brand, linear-gradient(90deg, #FE7A00 0%, #F83600 100%)) !important;
                background-clip: text !important;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
  .content-sm{
    max-width: calc(100% - 320px);
    margin: auto;
  }

  .job-lst{
    .item{
      padding: 24px;
      border-radius: 4px;
      border: 1px solid #E2E6F2;
      background: #FFF;
      margin-bottom: 16px;
      h4{
        color: #212633;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0px;
        margin-bottom: 16px;
        cursor: pointer;
      }
      span{
        color: #76809B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        svg{
          margin-right: 8px;
        }
      }
      .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div{
          display: flex;
          align-items: center;
          gap: 16px;
          &>span{
            display: flex;
            align-items: center;
          }
        }
        &>span{
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
  section{
    box-shadow: none;
    .header{
      text-align: center;
      margin-bottom: 40px;
      box-shadow: none;
      h2{
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        margin-bottom: 24px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px
      }
    }
  }

  .pag{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>span{
      color: #76809B;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    .p-paginator{
      // .p-paginator-pages{
        button{
          color: #99A2BC !important;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          width: 24px;
          height: 24px;
          min-width: 0px;
          border-radius: 2px;
          &.p-highlight{
            color: #fff !important;
            background: #0052CC !important
          }
        }
      // }
      .p-paginator-rpp-options{
        height: auto !important;
        width: auto;
        padding: 0px 5px;
        border-color: #99A2BC !important;
        .p-dropdown-label{
          color: #99A2BC;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding: 0px;
        }
        .p-dropdown-trigger{
          width: auto;
          .p-dropdown-trigger-icon{
            font-size: 12px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .filter{
    padding: 20px 16px;
    border-radius: 8px;
    border: 1px solid#E2E6F2;
    background: #FFF;
    hr{
      height: 1px;
      margin: 32px 0px;
      background: #EDF1FA;
    }
    .item{
      h3{
        color: #212633;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        span{
          color: #0052CC;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          cursor: pointer;
        }
        margin-bottom: 24px;
      }
      .input-filter{
        position: relative;
        input{
          border-radius: 4px;
          border: 1px solid #E2E6F2;
          display: flex;
          padding: 8px 80px 8px 48px;
          align-items: center;
          font-size: 14px;
          width: 100%;
          height: 44px;
          outline: none;
          &::-ms-input-placeholder {
            font-size: 14px;
            color: #76809B;
          }
          &::placeholder {
            font-size: 14px;
            color: #76809B;
          }
        }
        svg{
          position: absolute;
          top: 8px;
          left: 16px;
        }
        button{
          position: absolute;
          right: 8px;
          top: 8px;
          height: 28px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .fields{

      &.checkbox{
        max-height: 200px;
        overflow: auto;
        .item{
          margin-bottom: 14px;
          display: flex;
          gap: 8px;
          align-items: start;
          label{
            line-height: 1;
          }
        }
        .p-checkbox{
          height: auto;
        }
      }
    }

    p{
      color: #76809B;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    button{
      &.w-full{
        justify-content: center;
        .p-button-label{
          flex: none;
        }
      }
    }

  }
  footer{
    margin-top: 20px;
    .news{
        padding-left: 160px;
        padding-right: 160px;
    }
    .footer{
      background: url(assets/images/footer-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 32px 40px;
      padding-bottom: 0px;
      color: #E2E6F2;
      a{
        text-decoration: none;
      }
      .main{
        .left{
          .logo{
            margin-bottom: 8px;
          }
          p{
            color: #E2E6F2;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
          .social{
            display: flex;
            gap: 24px;
            margin-bottom: 24px;
            margin-top: 24px;
          }
          .app{
            display: flex;
            gap: 16px;
          }
        }
        .right{
          h2{
            color: #FFF;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 24px;
          }
          ul{
            li{
              color: #E2E6F2;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
      .main{
        padding-bottom: 32px;
      border-bottom: 1px solid hsla(225, 38%, 92%, 0.1);
      }
      .copyright{
        display: flex;
        justify-content: space-between;
        color: #E2E6F2;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        padding-top: 24px;
        padding-bottom: 24px;
        nav{
          display: flex;
          gap: 5px;
          align-items: center;
          a{
            color: #E2E6F2;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

  }
  .single-post{
    max-width: calc(100% - 320px);
    margin: auto;
    .main{
      padding-right: 25px;
    }
    .back{
      display: flex;
      align-items: center;
      gap: 10px;
      color: #76809B;
      padding: 24px 0px 10px;
    }
    .banner{
      position: relative;
      margin-bottom: 60px;
      .main{
        width: 100%;
        height: auto;
        border-radius: 16px;
      }
      .icon-logo{
        background-color: #fff;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #E2E6F2;
        width: 62px;
        height: 62px;
        position: absolute;
        left: 35px;
        top: calc(100% - 45px);
        z-index: 4;
        object-fit: cover;
      }
    }

    .head-single-content{
      padding-bottom: 32px;
      border-bottom: 1px solid #EDF1FA;
      .row-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        h2{
          color: #212633;
          font-size: 30px;
          font-weight: 600;
          line-height: 38px;
        }
        .link{
          display: flex;
          gap: 8px;
          justify-content: end;
        }
      }
      h4{
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
    .btn-tran{
      background: none;
      &:hover{
        background: none;
      }
      .p-button-label{
        color: #0C3399 !important;
      }

    }
    .icon-text{
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #EDF1FA;
      .item{
        display: flex;
        gap: 12px;
        p{
          color: #76809B;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 4px;
        }
       h4{
        color: #212633;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
       }
      }
    }
  }
  .section-info{
    padding: 30px 0px;
    padding: 30px 0px 10px;
    border-bottom: 1px solid #EDF1FA;
    h2{
      margin-bottom: 8px;
      color: #212633;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      font-family: 'SFProTextRegular';
    }
    ul{
      margin-bottom: 24px;

      li{
        color: #212633;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        position: relative;
        padding-left: 20px;
        font-family: 'SFProTextRegular';
        &::after{
          content: '−';
          font-size: 14px;
          line-height: 20px;
          position: absolute;
          font-family: 'SFProTextRegular';
          top: 0px;
          left: 7px;
        }
      }
    }
  }
  .form-register{
    padding: 30px 0px;
    h3{
      color: #212633;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .p-accordion-tab{
      padding-bottom: 10px;
    }
  }
  .sidebar{
    padding: 20px 16px;
    border-radius: 8px;
    border: 1px solid #E2E6F2;
    background: #FFF;
    h4{
      color: #212633;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }
    .related{
      .item{
        border-radius: 8px;
        background: rgba(226, 232, 240, 0.20);
        padding: 24px;
        margin-bottom: 16px;
        h3{
          color: #212633;
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          margin-bottom: 16px;
        }
      }
    }
  }
  a{
    color: #0052CC;
  }
  img{
    max-width: 100%;
  }
}

app-type-markdown{
  md-editor{
    h2{
      margin-bottom: 10px;
    }
    ul{
      padding-left: 20px;
      margin-bottom: 20px;
      li{
        list-style: disc;
      }
    }
  }
}

.isVisibility {
  visibility: hidden !important;
}
