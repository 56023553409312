@import 'content';
@import './_utils';
@import 'menu.scss';
@import './_table.scss';
@import 'fields.scss';
@import 'icon.scss';
@import 'icon-style.scss';

.header{
  position: sticky;
  top: 0px;
  z-index: 111;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}
.app-body{
  &>main{
    &.vertical{
      display: flex;
      justify-content: end;
      aside{
        position: fixed;
        top: 69px;
        height: calc(100vh - 83px);
        background: #fff;
        z-index: 1;
        border-radius: 4px;
        left: 0px;
        width: 235px;
        overflow-y: auto;
        .p-panelmenu {
          background: #FFFFFF;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
          height: 100%;
          .p-panelmenu-panel{
            box-shadow: none;
            &>.parent_active{
              &>.p-panelmenu-header-link{
                background: #f9f9f9;

              }
            }
            .p-panelmenu-header-link{
              .p-panelmenu-icon{
                color: #717171;
              }
            }
            .active{
              .p-menuitem-link{
                background: #efeeee;;
              }
            }
            .p-submenu-list{
              padding-left: 0px;
              &>li{
                &>a{
                  padding-top: 10px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid #f7f7f7;
                  padding-left: 18px;
                }
              }
            }
          }
          .p-panelmenu-header{
            & > a{
              padding-top: 15px;
              padding-bottom: 15px;
              border-bottom: 1px solid #f9f9f9;
              padding-left: 10px;
            }
          }

        }
      }
      .bread-crumb{
        width: calc(100% - 27px);
        margin: auto;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }
      .main-grid{
        padding-top: 15px;
      }
      &>.content{
        width: calc(100% - 235px);
        //margin-right: auto;
        app-home{
          .box{
            &.h2100{
              height: 100%;
            }
          }
          .p-dataview-list{
            height: calc(100vh - (67vh - 10px)) !important;
            max-height: 100%;
          }
          .content-body{
            padding-bottom: 0px !important;
            height: 100%;
            &>.grid{
              height: 100%;
              &>div{
                padding-bottom: 0px;
              }
            }
          }
        }
      }
      .snnvBottom {
        height: 66ch !important;
      }
    }
  }
}
