@media screen and (max-width: 960px) {
    .table-row{
        &.p-datatable{
            .p-datatable-tbody {
                &> tr{
                    & > td{
                        padding: 3px 10px !important;
                    }
                }
            }
        }
    }
}
// .ag-row-footer.ag-row-level-0  {
//     .ag-cell-range-right {
//         font-weight: 600;
//     }
// }
// .ag-row-footer.ag-row-level--1  {
//     .ag-cell-range-right {
//         font-weight: 600;
//         color: red;
//     }
// }
// .ag-row-group-expanded {
//     .ag-group-value, .ag-group-child-count {
//         font-weight: 600;
//     }
// }

// .ag-body-viewport{
//     .ag-row-level-1{

//         .ag-details-row-fixed-height{
//             padding: 5px 20px !important;
//             .ag-details-grid-fixed-height{
//                 .ag-root-wrapper{
//                     background-color: var(--primary-color-text) !important;
//                 }
//             }
//             &>div{
//                 &:first-child{
//                     height: auto !important;
//                     padding: 7px 0px !important;
//                 }
//             }

//         }
//     }


// }

.ag-tabs-header {
    display: flex;
    [aria-label="general"] {
        order: 2 !important;
    }
    [aria-label="filter"] {
        order: 1 !important;
    }
    [aria-label="columns"] {
        order: 3 !important;
    }
}

formly-field {
    .p-field {
        input {
            &:disabled {
                background-color: #ccc !important;
            }
        }
        position: relative;
        padding-bottom: 17px;

        .ng-invalid.ng-touched {
            border-color: #f44336 !important;
            input {
                border: 1px solid #f44336 !important;
            }
        }
        small{
            &.p-error{
                position: absolute;
                bottom: -3px;
            }
        }
        .field {
            &.grid{
                margin-bottom: 0px;
            }
            margin-bottom: 0px;
        }
    }
}

.checkbox {
    .p-field {
        display: flex !important;
        height: 86%;
        align-items: end;
        label {
            order: 1;
            margin-left: 12px;
            margin-bottom: 10px;
        }
        app-nz-checkbox {
            .field {
                .col{
                  .p-checkbox {
                    display: flex;
                    align-items: center;
                    .p-checkbox-box {
                        border-radius: 2px;
                        width: 18px;
                        height: 18px;
                    }
                  }
                }

            }
        }
    }
}

.table_detail {
    input {
        &:disabled {
            background-color: #ccc !important;
        }
    }

    th, td{
        padding-right: 10px;
    }
    formly-field {
        .p-field {
            small{
                &.p-error{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }
}


.noti-number{
    display: block;
    color: #fff;
    border-radius: 2px;
    padding: 3px 6px;
    line-height: 1;
    margin-top: 0;
    font-size: 12px;
    font-family: "SFProTextRegular";
    font-weight: 400;
    width: 100%;
    position: relative;
    border-radius: 3px;
    color: #ffffff;
    // border: 1px solid c;
    // padding-left: 10px;

    &:after {
        // content: '';
        // width: 4px;
        // height: 4px;
        // display: block;
        // position: absolute;
        // top: 8px;
        // left: 0px;
        // border-radius: 100%;
    }
    &.bg-primary{
        background: #0d6efd;
    }
    &.bg-secondary {
        // background: #6c757d;
        // border: 1px solid #6c757d;
        // color: #6c757d;
        background: #6c757d;

        &:after {
            background: #6c757d;
        }
    }
    &.bg-danger{
        // color: #c82333;
        background: #dc3545;
    }
    &.bg-warning{
        background: #ffc107;
    }
    &.bg-success{
        // color: #34C759;
        background: #198754;
        width: 100%;
    }
    &.bg-dark{
        color: #ffffff;
        background: #212529
    }
    &.bg-info{
        color: #ffffff;
        background: #0dcaf0;
    }
    &.bg-light{
        color: #212529;
        background: #f8f9fa;
    }
}

.ag-floating-bottom {
    .ag-row-pinned.ag-row-level-0 {
        background-color: #f1efef !important;
    }

    .p-avatar {
        display: none;
    }
}

.chamcong {
  .date {
    display: flex !important;
    flex-direction: column !important;
    label {
      color: #212633;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      font-weight: 500;
      display: block;
      transition: all 0.3s;
      font-family: "SFProTextRegular";
    }
    .p-calendar {
      width: 100% !important;
      .p-inputtext {
        width: 100% !important;
        border: 1px solid #E2E6F2;
        border-radius: 4px !important;
        height: 40px;
      }
    }
  }
}
.app-insert-data {
  .list-panel{

  }
}

